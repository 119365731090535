import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export default function LanguageProvider({ children }) {
    const [currentLanguage, setCurrentLanguage] = useState('en');

    useEffect(() => {
        const savedLanguage = localStorage.getItem('lang') || navigator.language.slice(0, 2);
        setCurrentLanguage(savedLanguage === 'en' ? 'en' : 'ro');
    }, []);

    const changeLanguage = (lang) => {
        setCurrentLanguage(lang);
        localStorage.setItem('lang', lang);
    };

    return (
        <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};