import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import header_background from "../assets/header_background.jpg";
import frame_lines from "../components/svg/frame_lines.png"
import frame_dots_long from "../components/svg/frame_dots_long.png"
import header_about_us from "../assets/header_about_us.jpg";
import {useContext} from "react";
import {LanguageContext} from "../helpers/LanguageContext";
import LANGUAGES from "../helpers/translate";

export default function ProBono() {

    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    return (
        <div>
            <Sidebar></Sidebar>
            {/* HEADER */}

            <div className="relative h-[350px] md:h-[400px] max-h-2xl w-full">
                <img src={header_about_us} alt="Background Image"
                     className="absolute inset-0 w-full h-full object-cover filter"/>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div
                    // style={{ backgroundImage: `url(${background})` }}
                    className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col items-center justify-center my-auto">
                    <img className="hidden lg:flex absolute h-10 md:h-12 top-24 right-0 md:right-6" src={frame_lines}/>
                    <div className="flex md:hidden mx-auto">
                        {/*<img*/}
                        {/*    className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                    <div className="w-full flex flex-col justify-center pt-12">
                        <h1 className="text-center text-3xl lg:text-4xl text-white font-bold">Pro Bono</h1>
                        <span className='w-24 mx-auto bg-white h-1 mt-1.5 rounded-lg'/>
                        {/*<span*/}
                        {/*    className=" text-start text-base lg:text-lg text-white font-medium mt-4">Iata cateva informatii despre cine sunt noi</span>*/}
                    </div>
                    <div className="hidden lg:flex w-3/12">
                        {/*<img*/}
                        {/*    className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                </div>
            </div>

            <div className="w-full ">
                <div className="h-5"></div>

                <div className={currentLanguage === "ro" || !currentLanguage ? 'w-11/12 mx-auto container' : 'hidden'}>
                    <p className="text-lg">
                        Expresia <span className="italic font-bold">„pro bono”</span> provine din latinescul <span
                        className="italic">„pro bono publico”</span>, care înseamnă <span className="italic">„pentru binele public”</span>,
                        referindu-se la o activitate voluntară, realizată în beneficiul comunității, astfel că <span
                        className="font-bold">serviciile juridice</span> <span
                        className="font-bold italic">pro bono</span> se referă la asistența juridică oferită în mod
                        gratuit de către avocat persoanelor vulnerabile fără surse de venit sau fără mijloace financiare
                        suficiente.
                    </p>

                    <p className="mt-5 text-lg">
                        În domeniul juridic din România, activitatea juridică pro bono nu este reglementantă, însă nu
                        este nici interzisă, practica fiind destul de rar întâlnită, dar se impune precizarea diferenței
                        dintre ajutorul public judiciar care este un seriviciu pus la dispoziția justițiabililor de
                        către autoritățile statului în anumite cazuri și activitatea juridică pro bono, care se acordă
                        la inițiativa avocatului, conform codului deontologic al avocaților din U.E. și adoptat de
                        Uniunea Națională a Barourilor din România care prevede faptul că muncă avocatului include și
                        îndatoriri și obligații față de publicul larg, public pentru care profesia de avocat este <span
                        className="italic">„un mijloc esențial de apărare a drepturilor omului în fața Statului și a altor puteri din societate” (Decizia U.N.B.R. Nr. 1486/2007).</span>
                    </p>

                    <p className="mt-5 text-lg">
                        Pentru a întări sistemul de justiție și a spori practiv accesul la justiție, Uniunea Europeană,
                        ONU și alte organizații naționale și internaționale încurajează activitatea pro bono din
                        domeniul juridic și o sprijină prin colaborarea și coordonarea cu alți furnizori de asistență
                        juridică, astfel că această activitate se bucură de un suport lărgit la nivel național și
                        internațional.
                    </p>

                    <p className="mt-5 text-lg">
                        În acest context, în situația în care considerați că aveți nevoie asistare sau reprezentare în
                        diferite situații, dar apreciați că datorită situației financiare nu vă permiteți un avocat, vă
                        rugăm să completați formularul pentru a putea fi contactat/ ă de către colegii noștri în vederea
                        obținerii unei programări la sediul cabinetului de avocatură unde vom discuta în condiții de
                        confidențialitate aceste toate aspectele.
                    </p>
                </div>

                <div className={currentLanguage === "en" ? 'w-11/12 mx-auto container' : 'hidden'}>
                    <p className="text-lg">
                        The expression <span className="italic font-bold">"pro bono"</span> comes from the Latin
                        phrase <span className="italic">"pro bono publico"</span>, which means <span className="italic">"for the public good"</span>.
                        It refers to voluntary activities performed for the benefit of the community. As such, <span
                        className="font-bold">pro bono legal services</span> <span
                        className="font-bold italic">pro bono</span> refer to legal assistance provided free of charge
                        by lawyers to vulnerable individuals who lack income or sufficient financial resources.
                    </p>

                    <p className="mt-5 text-lg">
                        In the legal field in Romania, pro bono legal work is not regulated but is also not prohibited.
                        Although this practice is relatively rare, it is essential to clarify the distinction between
                        public judicial aid, which is a service offered to litigants by state authorities in specific
                        cases, and pro bono legal work, which is provided at the lawyer's initiative. This is in
                        accordance with the code of ethics for lawyers in the EU, adopted by the National Union of Bar
                        Associations in Romania, which states that a lawyer's work also includes duties and obligations
                        to the broader public. For the public, the legal profession is seen as <span className="italic">"an essential means of defending human rights against the State and other powers in society" (Decision of the N.U.B.R. No. 1486/2007).</span>
                    </p>

                    <p className="mt-5 text-lg">
                        To strengthen the justice system and enhance access to justice, the European Union, the United
                        Nations, and other national and international organizations actively encourage pro bono legal
                        work and support it through collaboration and coordination with other legal aid providers. As a
                        result, this activity enjoys broad support both nationally and internationally.
                    </p>

                    <p className="mt-5 text-lg">
                        In this context, if you believe you need legal assistance or representation in various
                        situations but feel that your financial situation does not allow you to hire a lawyer, please
                        complete the form so our colleagues can contact you to arrange an appointment at our law office.
                        During this appointment, we will confidentially discuss all relevant aspects.
                    </p>
                </div>


                <div className="h-5"></div>
            </div>
            <Footer></Footer>
        </div>
    )
}