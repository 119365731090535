import Sidebar from "../components/Sidebar";
import header_background from "../assets/header_background.jpg";
import Footer from "../components/Footer";


export default function GeneralActivity() {

    return (
        <div className="w-full">
            <Sidebar/>

            <div className="w-full">
                {/*<img className="z-0 w-full h-28 max-h-[20rem] xl:max-h-[20rem] 2xl:max-h-[30rem] overflow-hidden object-cover bg-cover bg-no-repeat opacity-90" src={header_background} />*/}
                <div className="relative overflow-hidden bg-cover bg-no-repeat" style={{
                    backgroundPosition: '50%',
                    backgroundImage: `url(${header_background})`,
                    height:'350px',
                    opacity: '80%'
                    }}>
                    <div
                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed">
                        <div className="flex h-full items-center justify-center">
                            <div className="px-6 text-center md:px-12">
                                <h1 className="mt-2 mb-16 text-3xl font-bold text-[#023F30] ">
                                    Activitati generale
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<h1 className="text-2xl text-gray-400 text-center font-semibold mt-6">Activitati generale</h1>*/}


                <div className="lg:container mx-auto mx-auto py-2.5 md:py-5">
                    <div className="flex flex-col w-11/12 sm:w-11/12 px-4 2xl:px-12 2xl:w-full flex mx-auto text-lg">
                        <div className="w-full grid sm:grid-rows-2 lg:grid-cols-2 gap-2">
                            <div className="w-full py-2 lg:py-0 lg:w-9/12 mx-auto">
                                <span className="uppercase font-semibild py-1.5"><span>Consultanta juridica</span></span>
                                <div className="mx-auto bg-gray-200 h-0.5 w-full pmy-3"></div>
                            </div>
                            <div className="w-full py-2 lg:py-0  lg:w-9/12 mx-auto">
                                <span className="uppercase font-semibild py-1.5">Redactarea de opinii juridice</span>
                                <div className="mx-auto bg-gray-200 h-0.5 w-full pmy-3"></div>
                            </div>
                            <div className="w-full py-2 lg:py-0  lg:w-9/12 mx-auto">
                                <span className="uppercase font-semibild py-1.5">Redactare contracte customizate</span>
                                <div className="mx-auto bg-gray-200 h-0.5 w-full pmy-3"></div>
                            </div>
                            <div className="w-full py-2 lg:py-0  lg:w-9/12 mx-auto">
                                <span className="uppercase font-semibild py-1.5">Activitati judiciare</span>
                                <div className="mx-auto bg-gray-200 h-0.5 w-full pmy-3"></div>
                            </div>
                            <div className="w-full py-2 lg:py-0  lg:w-9/12 mx-auto">
                                <span className="uppercase font-semibild py-1.5">Modificarea contractelor</span>
                                <div className="mx-auto bg-gray-200 h-0.5 w-full pmy-3"></div>
                            </div>
                            <div className="w-full py-2 lg:py-0  lg:w-9/12 mx-auto">
                                <span className="uppercase font-semibild py-1.5">Reprezentare in fata instantelor de judecata</span>
                                <div className="mx-auto bg-gray-200 h-0.5 w-full pmy-3"></div>
                            </div>
                            <div className="w-full py-2 lg:py-0  lg:w-9/12 mx-auto">
                                <span className="uppercase font-semibild py-1.5">Asistare la negocieri</span>
                                <div className="mx-auto bg-gray-200 h-0.5 w-full pmy-3"></div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Footer/>

        </div>
    )
}