const LANGUAGES = {
    en: {
        protectBusiness: "We protect business interests by providing safety and financial predictability",
        analyzeRisks: "We analyze and eliminate the risks of financial losses caused by late invoice payments",
        manageEmployeeDiscipline: "We manage vulnerabilities related to employee indiscipline",
        permanentLegalConsulting: "We ensure permanent legal consulting for your business",
        securePartnerships: "We guarantee solid partnerships for safe and prosperous businesses",
        more: "More",
        areas_of_activity: "Areas of activity",
        p_areas_of_activity: "The law firm, through specialized lawyers, offers you consultancy, assistance and representation in order to obtain the best results in areas regarding",
        contact_us: "Contact us",
        home: "Home",
        about_us: "About us",
        terms_conditions: "Terms and conditions",
        title_footer: "We are here to provide you with the legal support you need",
        title_banner: "Are you interested in how our services work? View more details",
        quote: "In a civilized society, lawyers are essential. They are the ones who fight for justice on behalf of the voiceless. Through their skill and dedication, they protect fundamental freedoms and ensure that the law is an instrument of justice, not oppression.",
        benefits: "The benefits of teaming up with us",
        sub_title_benefits: "Embark with us on a transformative journey, where personalized solutions and dedicated support redefine the success of collaboration.",
        progressively_oriented: "Progressively oriented",
        subtitle_progressively_oriented: "Innovative progress with dynamic strategies and future-oriented solutions.",
        innovative_vision: "Innovative vision",
        subtitle_innovative_vision: "Developing solutions through future strategies and innovative vision",
        title_blogs: "Read our collection of articles",
        subtitle_blogs: "Explore our diverse collection of articles for expert insights and knowledge on a topic you can consult with us on",
        more_blogs: "Search for other articles",
        read_more: "Read more",
        general_activity: "General activity of the Law Firm",
        subtitle_general_activity: "With an approach oriented towards excellence, we offer complete legal services, adapted to the needs of each client. We dedicate ourselves to the efficient resolution of legal situations, emphasizing professionalism, confidentiality and sustainable results, promoting the ethical principles that characterize the actions that a lawyer carries out in the exercise of the profession. In this context, the set of services and actions that lawyers carry out in the exercise of the profession include, but are not limited to",
        first_general_activity: "Developing innovative legal strategies, adapted to current market requirements and legislative changes",
        two_general_activity: "Negotiating and drafting complex commercial contracts, ensuring the protection of the client's interests;",
        three_general_activity: "Assistance and representation in judicial and arbitration proceedings, with an approach focused on obtaining the most favorable solutions;",
        four_general_activity: "Consulting in the field of legal compliance and corporate governance, to prevent risks and optimize decision-making processes;",
        five_general_activity: "Resolving disputes through mediation or other alternative methods, offering our clients fast and efficient options.",
        title_activity: "Our efforts are directed towards the success of our partners and thus we offer",
        subtitle_activity: "Customized solutions for your company's objectives, ensuring support and an efficient path to success",
        other_articles: "Other articles",
        contact_right_now: "Contact us right now",
        subtitle_contact_right_now: "Sign in now for fast support and discover the benefits of contacting us immediately for customized solutions",
        phoneNo: "Phone number",
        send_message: "Would you like to send us a message?",
        subtitle_send_message: "Send us a message and we will respond as soon as possible.",
        subject: "Subject",
        message: "Message",
        btn_send_message: "Send message",
        p_general_activity: "In this context, our services and actions include, but are not limited to:",
        p_two_general_activity: "By integrating innovative progress and dynamic strategies, we are dedicated to identifying the most appropriate legal solutions, oriented towards the long-term success of our clients.",
    },
    ro: {
        protectBusiness: "Protejăm interesele afacerii oferind siguranță și predictibilitate financiară",
        analyzeRisks: "Analizăm și eliminăm riscurile pierderilor financiare generate de neîncasarea la timp a facturilor",
        manageEmployeeDiscipline: "Gestionăm vulnerabilitățile legate de indisciplina angajaților",
        permanentLegalConsulting: "Asigurăm consultanță juridică permanentă pentru afacerea dvs.",
        securePartnerships: "Garantăm parteneriate solide pentru afaceri sigure și prospere",
        more: "Aflati mai multe",
        areas_of_activity: "Domenii de activitate",
        p_areas_of_activity: "Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind\n",
        contact_us: "Contacteaza-ne",
        home: "Acasa",
        about_us: "Despre noi",
        terms_conditions: "Termeni si conditii",
        title_footer: "Suntem aici pentru a vă oferi sprijinul juridic de care aveți nevoie",
        title_banner: "Vă interesează cum funcționează serviciile noastre ? Vizualizati mai multe detalii",
        quote: "Într-o societate civilizată, avocații sunt esențiali. Ei sunt cei care se luptă pentru dreptate în numele celor care nu au voce. Prin competența și dedicarea lor, ei protejează libertățile fundamentale și se asigură că legea este un instrument al dreptății, nu al opresiunii",
        benefits: "Beneficiile de a face echipă cu noi",
        sub_title_benefits: "Porniți alături de noi într-o călătorie transformatoare, în care soluțiile personalizate și sprijinul dedicat redefinesc succesul colaborării.",
        progressively_oriented: "Orientat progresiv",
        subtitle_progressively_oriented: "Progresul inovator cu strategii dinamice și soluții orientate spre viitor.",
        innovative_vision: "Viziune inovatoare",
        subtitle_innovative_vision: "Elaborarea de soluții prin strategii de viitor și viziune inovatoare",
        title_blogs: "Citiți colecția noastră de articole",
        subtitle_blogs: "Explorați colecția noastră diversă de articole pentru perspective și cunoștințe de specialitate pe un subiect pe care îl puteți consulta cu noi.",
        more_blogs: "Căutați alte articole",
        read_more: "Citeste mai mult",
        general_activity: "Activitatea generală a Cabinetului de Avocatură",
        subtitle_general_activity: "Cu o abordare orientată spre excelență, oferim servicii juridice complete și personalizate, adaptate specificului fiecărui client. Ne dedicăm soluționării eficiente a provocărilor juridice printr-un angajament ferm față de profesionalism, confidențialitate și obținerea unor rezultate sustenabile. Toate acțiunile noastre sunt ghidate de principii etice solide, care definesc activitatea noastră ca avocați și parteneri de încredere.",
        first_general_activity: "Elaborarea de strategii juridice inovatoare, adaptate cerințelor actuale ale pieței și schimbărilor legislative",
        two_general_activity: "Negocierea și redactarea contractelor comerciale complexe, asigurând protecția intereselor clientului",
        three_general_activity: "Asistarea și reprezentarea în proceduri judiciare și arbitrale, cu o abordare axată pe obținerea celor mai favorabile soluții;",
        four_general_activity: "Consiliere în domeniul conformității legale și guvernanței corporative, pentru a preveni riscurile și a optimiza procesele decizionale;",
        five_general_activity: "Soluționarea litigiilor prin mediere sau alte metode alternative, oferind clienților noștri opțiuni rapide și eficiente.",
        title_activity: "Eforturile noastre se îndreaptă spre succesul partenerilor noștri și astfel oferim:",
        subtitle_activity: "Soluții personalizate pentru obiectivele societății dvs asigurând suport și o cale eficienta spre succes",
        other_articles: "Alte articole",
        contact_right_now: "Contactați-ne chiar acum",
        subtitle_contact_right_now: "Conectați-vă acum pentru asistență rapidă și descoperiți beneficiile de a ne contacta imediat pentru soluții personalizate",
        phoneNo: "Numar de telefon",
        send_message: "Doresti sa ne trimiti un mesaj ?",
        subtitle_send_message: "Trimite-ne un mesaj si iti vom raspund in cel mai scurt timp.",
        subject: "Subiect",
        message: "Mesaj",
        btn_send_message: "Trimite mesaj",
        p_general_activity: "În acest context, serviciile și acțiunile noastre includ, dar nu se limitează la:",
        p_two_general_activity: "Prin integrarea progresului inovator și a strategiilor dinamice, suntem dedicați identificării celor mai potrivite soluții juridice, orientate spre succesul pe termen lung al clienților noștri.",
    },
};

export default LANGUAGES