import DropDown from "./DropDown";
import options from "../assets/options.png";
import {useEffect, useState, Fragment, useContext} from "react";
import flag_ro from "../components/svg/flag-romania.svg";
import flag_usa from "../components/svg/flag-us.svg";
import {useLocation, useNavigate} from "react-router-dom"
import logo from "./svg/logo.png"
import LANGUAGES from "../helpers/translate";
import {Menu, Transition} from '@headlessui/react'
import {LanguageContext} from "../helpers/LanguageContext";

export default function Sidebar(history) {
    const navigate = useNavigate()

    function changeRoute(page) {
        navigate(page)
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    // const [currentLanguage, setCurrentLanguage] = useState("en"); // Default language is English.
    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    const [activeRoute, setActiveRoute] = useState('');

    useEffect(() => {
        // Setează ruta curentă din browser
        setActiveRoute(window.location.pathname);
    }, []);

    const handleRouteChange = (route) => {
        setActiveRoute(route); // Actualizează ruta activă
        changeRoute(route); // Schimbă pagina
    };

    const location = useLocation()

    return (
        <div>
            <div className="fixed top-0 h-1/12 object-cover w-full z-50">
                {/* TOP */}
                <div className="top-0 w-full">
                    <div className="bg-blue">
                        <div
                            className="relative w-11/12 z-20 sm:w-11/12 2xl:px-12 2xl:w-full md:px-0 flex items-center justify-center md:justify-between mx-auto py-4">
                            <button className="md:hidden absolute menu-button -left-1" onClick={toggleSidebar}>
                                {/*<span role="img" aria-label="menu">🍔</span>*/}
                                <span className="text-white"><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    ></path>
                                </svg>
                                </span>
                            </button>
                            <div className={`sidebar bg-blue ${isOpen ? 'open' : ''}`}>
                                <button className="close-button float-right" onClick={toggleSidebar}>
                                    {/*<span role="img" aria-label="close">X</span>*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="w-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                                <button className=" px-4 pt-3 flex flex-col justify-center">
                                {/*<span className="text-xl md:text-lg text-white font-cormorant"*/}
                                {/*      onClick={() => changeRoute("/")}>Paul Moise</span>*/}
                                {/*    <span className="text-[10px] text-white uppercase font-bold"*/}
                                {/*          onClick={() => changeRoute("/")}>Cabinet avocatura</span>*/}
                                {/*    <img src={logo} alt='Logo' className="w-48" />*/}
                                </button>
                                <div className="sidebar-content text-md space-y-4 mt-6">
                                    <ul>
                                        <li onClick={() => changeRoute("/")} >
                                            <span className={`nav-item font-semibold ${location.pathname === '/' ? 'active' : ''}`}>
                                                {t.home}
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/despre-noi")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/despre-noi' ? 'active' : ''}`}>
                                                {t.about_us}
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/domenii/activitate")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/domenii/activitate' ? 'active' : ''}`}>
                                                {t.areas_of_activity}
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/blogs")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/blogs' ? 'active' : ''}`}>
                                                Blog
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/pro-bono")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/pro-bono' ? 'active' : ''}`}>
                                                Pro Bono
                                            </span>
                                        </li>
                                    </ul>

                                    <div className="mt-6">
                                        <button
                                            className="w-full bg-white rounded-xl text-white text-xs py-3.5 px-4 mt-4">
                                    <span className="color-blue font-bold opacity-100"
                                          onClick={() => changeRoute("/contact")}>{t.contact_us}</span>
                                        </button>
                                        {/*<ul className="flex flex-col text-base text-white font-semibold mt-4">*/}
                                        {/*    <li className="flex flex-row">*/}
                                        {/*        <img className="w-5" src={flag_ro}/>*/}
                                        {/*        <span className="ml-1.5">RO</span>*/}
                                        {/*    </li>*/}
                                        {/*    /!*<li className="flex flex-row">*!/*/}
                                        {/*    /!*    <img className="w-4" src={flag_usa} />*!/*/}
                                        {/*    /!*    <span>EN</span>*!/*/}
                                        {/*    /!*</li>*!/*/}
                                        {/*</ul>*/}
                                        <ul className="flex flex-col text-base text-white font-semibold">
                                            {/*<li className="flex flex-row">*/}
                                            {/*    <img className="w-5" src={flag_ro}/>*/}
                                            {/*    <span className="ml-1.5">RO</span>*/}
                                            {/*</li>*/}
                                            <div className="pt-4">
                                                <Menu as="div" className="relative w-full inline-block text-left">
                                                    <div>
                                                        <Menu.Button
                                                            className="inline-flex w-full justify-start rounded-md bg-white/20 text-sm font-medium text-white hover:bg-white/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                                            {currentLanguage === "ro" ?
                                                                <div className="inline-flex px-3 py-1.5 ">
                                                                    <img className="w-5" src={flag_ro}/>
                                                                    <span className="ml-1.5">Romana</span>
                                                                </div> : <div className="inline-flex px-1.5 py-1.5 ">
                                                                    <img className="w-5" src={flag_usa}/>
                                                                    <span className="ml-1.5">English</span>
                                                                </div>}

                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            className=" right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                            <div className="px-1 py-1 ">
                                                                <Menu.Item>
                                                                    {({active}) => (
                                                                        <button onClick={() => changeLanguage('ro')}
                                                                                className={`${
                                                                                    active ? 'bg-blue text-white' : 'text-gray-900'
                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                        >
                                                                            {/*{active ? (*/}
                                                                            {/*    <EditActiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*) : (*/}
                                                                            {/*    <EditInactiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*)}*/}
                                                                            <img className="w-5" src={flag_ro}/>
                                                                            <span className="ml-1.5">Romana</span>
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({active}) => (
                                                                        <button onClick={() => changeLanguage('en')}
                                                                                className={`${
                                                                                    active ? 'bg-blue text-white' : 'text-gray-900'
                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                        >
                                                                            {/*{active ? (*/}
                                                                            {/*    <DuplicateActiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*) : (*/}
                                                                            {/*    <DuplicateInactiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*)}*/}
                                                                            <img className="w-5" src={flag_usa}/>
                                                                            <span className="ml-1.5">English</span>
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>


                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                            {/*<li className="flex flex-row">*/}
                                            {/*    <img className="w-4" src={flag_usa} />*/}
                                            {/*    <span>EN</span>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}
                            <button onClick={() => {
                                changeRoute('/')
                            }} className="flex flex-col justify-center">
                                {/*<span className="text-2xl text-white font-cormorant"*/}
                                {/*      onClick={() => changeRoute("/")}>Paul Moise</span>*/}
                                {/*<span className="text-[12px] text-white uppercase font-bold"*/}
                                {/*      onClick={() => changeRoute("/")}>Cabinet avocatura</span>*/}
                                <img src={logo} alt='Logo' className="w-56 md:w-40 lg:w-56"/>
                            </button>
                            {/*<div className="w-10 md:w-0 sm:w-10">*/}

                            {/*    /!*<img src={options} onClick={openOptions}/>*!/*/}
                            {/*    <div id="options" >*/}
                            {/*        <div*/}
                            {/*            className="absolute w-10/12 h-10/12 left-8 text-s uppercase text-white font-semibold">*/}
                            {/*            <div className="fixed right-11 top-60">*/}
                            {/*                <ul className="flex flex-row text-s uppercase text-white font-semibold">*/}
                            {/*                    <li>RO</li>*/}
                            {/*                    <li>EN</li>*/}
                            {/*                </ul>*/}
                            {/*            </div>*/}
                            {/*            <ul className=" top-8"*/}
                            {/*            >*/}
                            {/*                <li style={{marginTop: "10px"}} onClick={() => changeRoute("/")}>Acasa</li>*/}
                            {/*                <li style={{marginTop: "10px"}} onClick={() => changeRoute("/about")}>Despre*/}
                            {/*                </li>*/}
                            {/*                <li style={{marginTop: "10px"}}*/}
                            {/*                    onClick={() => changeRoute("/domenii/activitate")}>Domenii de activitate*/}
                            {/*                </li>*/}
                            {/*                <li style={{marginTop: "10px"}}><DropDown/></li>*/}
                            {/*                <li style={{marginTop: "10px"}}>*/}
                            {/*                    <button onClick={() => changeRoute("/contact")}>*/}
                            {/*                        Contact*/}
                            {/*                    </button>*/}
                            {/*                </li>*/}
                            {/*                <li style={{marginTop: "10px"}}*/}
                            {/*                    onClick={() => changeRoute("/contact")}>Consultanta*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="flex-row items-center hidden md:flex">
                                <ul className="flex flex-row space-x-4 lg:space-x-6 lg:text-sm md:text-[12px] text-white font-semibold">
                                    <li className={location.pathname === '/' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/")}>{t.home}</li>
                                    <li className={location.pathname === '/despre-noi' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/despre-noi")}>{t.about_us}</li>
                                    <li className={location.pathname === '/domenii/activitate' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/domenii/activitate")}>{t.areas_of_activity}</li>
                                    <li className={location.pathname === '/blogs' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/blogs")}>Blog
                                    </li>
                                    <li className={location.pathname === '/pro-bono' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/pro-bono")}>Pro Bono
                                    </li>
                                </ul>
                                <button className="ml-8 bg-white rounded-xl text-white text-xs py-3.5 px-4">
                                    <span className="color-blue font-bold opacity-100"
                                          onClick={() => changeRoute("/contact")}>{t.contact_us}</span>
                                </button>
                                <div className="flex flex-col items-center w-16">
                                    <ul className="flex flex-col text-base text-white font-semibold">
                                        {/*<li className="flex flex-row">*/}
                                        {/*    <img className="w-5" src={flag_ro}/>*/}
                                        {/*    <span className="ml-1.5">RO</span>*/}
                                        {/*</li>*/}
                                        <div className="pl-1.5">
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <Menu.Button
                                                        className="inline-flex w-full justify-center rounded-md bg-white/20 text-sm font-medium text-white hover:bg-white/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                                        {currentLanguage === "ro" ?
                                                            <div className="inline-flex px-1.5 py-1.5 ">
                                                                <img className="w-5" src={flag_ro}/>
                                                                <span className="ml-1.5">RO</span>
                                                            </div> : <div className="inline-flex px-1.5 py-1.5 ">
                                                                <img className="w-5" src={flag_usa}/>
                                                                <span className="ml-1.5">EN</span>
                                                            </div>}

                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute right-0 mt-2 w-28 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                        <div className="px-1 py-1 ">
                                                            <Menu.Item>
                                                            {({active}) => (
                                                                    <button onClick={() => changeLanguage('ro')}
                                                                        className={`${
                                                                            active ? 'bg-blue text-white' : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                    >
                                                                        {/*{active ? (*/}
                                                                        {/*    <EditActiveIcon*/}
                                                                        {/*        className="mr-2 h-5 w-5"*/}
                                                                        {/*        aria-hidden="true"*/}
                                                                        {/*    />*/}
                                                                        {/*) : (*/}
                                                                        {/*    <EditInactiveIcon*/}
                                                                        {/*        className="mr-2 h-5 w-5"*/}
                                                                        {/*        aria-hidden="true"*/}
                                                                        {/*    />*/}
                                                                        {/*)}*/}
                                                                        <img className="w-5" src={flag_ro}/>
                                                                        <span className="ml-1.5">RO</span>
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <button onClick={() => changeLanguage('en')}
                                                                        className={`${
                                                                            active ? 'bg-blue text-white' : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                    >
                                                                        {/*{active ? (*/}
                                                                        {/*    <DuplicateActiveIcon*/}
                                                                        {/*        className="mr-2 h-5 w-5"*/}
                                                                        {/*        aria-hidden="true"*/}
                                                                        {/*    />*/}
                                                                        {/*) : (*/}
                                                                        {/*    <DuplicateInactiveIcon*/}
                                                                        {/*        className="mr-2 h-5 w-5"*/}
                                                                        {/*        aria-hidden="true"*/}
                                                                        {/*    />*/}
                                                                        {/*)}*/}
                                                                        <img className="w-5" src={flag_usa}/>
                                                                        <span className="ml-1.5">EN</span>
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </div>


                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        {/*<li className="flex flex-row">*/}
                                        {/*    <img className="w-4" src={flag_usa} />*/}
                                        {/*    <span>EN</span>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}