import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import header_background from "../assets/header_background.jpg";
import frame_lines from "../components/svg/frame_lines.png"
import frame_dots_long from "../components/svg/frame_dots_long.png"

export default function LoanContract() {
    return (
        <div>
            <Sidebar></Sidebar>
            {/* HEADER */}
            <div className="w-full bg-blue">
                <div className="relative w-11/12 mx-auto py-24 flex justify-center items-center">
                    <div className="pt-14">
                        <img className="absolute h-10 md:h-12 top-20 right-0 md:right-6" src={frame_lines}/>
                        <span className="text-3xl text-white text-center font-bold">Contractul de imprumut</span>
                    </div>
                </div>
            </div>
            <div className="w-full ">
                <div className="h-5"></div>

                <div className="w-11/12 mx-auto container">
                    <p className="text-lg">
                        Codul Civil prevede că împrumutul este de două feluri: de folosință (comodat) și de consumație.
                    </p>

                    <p className="mt-5 text-lg">
                        Împrumutul de folosință, reglementat de art. 2146 Cod Civil, este contractul cu titlu gratuit
                        prin care o parte, numită comodant, remite un bun mobil sau imobil celeilalte părți, numită
                        comodatar, penru a se folosi de acest bun, cu scoput de a-l restitui după un anumit timp, iar
                        împrumutul de consumație este „contractul prin care împrumutătorul remite împrumutatului o sumă
                        de bani sau alte asemenea bunuri fungibile şi consumptibile prin natura lor, iar împrumutatul se
                        obligă să restituie, după o anumită perioadă de timp, aceeaşi sumă de bani sau cantitate de
                        bunuri de aceeaşi natură şi calitate” conform dispozițiilor art. 2158 Cod Civil.
                    </p>

                    <p className="mt-5 text-lg">
                        În lumina textelor de lege citate supra, tragem concluzia că împrumutul între persoane fizice
                        care are ca obiect o sumă de bani reprezintă un împrumut de consumație și se află sub incidența
                        art. 2158 și urm. Cod Civil.
                    </p>

                    <p className="mt-5 text-lg">
                        În marea majoritate a cazurilor în care intervine un împrumut de consumație între două persoane
                        fizice, nu se fac verificările financiare de către împrumutător privind solvabilitatea celui
                        împrumutat, astfel că împrumutul intervine în virtutea principiului bunei credințe a părților.
                    </p>

                    <p className="mt-5 text-lg">
                        Conform dispozițiilor legale, atât bunul împrumutat, data scadenței, data returnării, condițiile
                        de returnare, dobânda (dacă este cazul) trebuie să fie clar sipulate în contract, la vedere, iar
                        în cele ce urmează vom face câteva precizări referitoare la contractul de consumație, mai exact
                        la împrumutul unei sume de bani între două persoane și la clauzele care trebuiesc stipulate în
                        contractul ce urmează a fi încheiat.
                    </p>

                    <p className="mt-5 text-lg">
                        Așa cum am arătat într-un articol anterior, recomandăm consultarea unui specialist în drept, a
                        unui avocat, care datorită cunoștințelor juridice, a experienței acumulate poate să ofere cele
                        mai bune sfaturi și soluții în vederea redactării contractului, iar acesta va avea un aport
                        considerabil chiar în fazele incipiente, în negocierea clauzelor înainte de a fi semnat
                        contractul pentru mai multă siguranță și pentru a ne asigura că am specificat toate clauzele pe
                        care le considerăm esențiale în cuprinsul contractului.
                    </p>

                    <p className="mt-5 text-lg">
                        Vom începe analiza prin prezentarea elementelor esențiale, a clauzelor ce esențiale ce urmează a
                        fi inserate în contractul de împrumut, astfel că pentru siguranța ambelor părți, în contract
                        trebuie prevăzute și identificate părțile acestuia, suma de bani împrumutată, data la care a
                        fost împrumutată, perioada pentru care se împrumută, data scadenței, iar dacă părțile doresc să
                        perceapă dobândă, trebuie specificată și dobânda.
                    </p>

                    <p className="mt-5 text-lg">
                        Cu privire la suma de bani împrumutată, vom menționa dispozițiile art. 2164 Cod Civil care
                        prevede la alin. (2) faptul că „în cazul în care împrumutul poartă asupra unei sume de bani,
                        împrumutatul nu este ţinut să înapoieze decât suma nominală primită, oricare ar fi variaţia
                        valorii acesteia, dacă părţile nu au convenit altfel” ceea ce înseamnă că dacă împrumutatul a
                        primit 10.000 EURO, în lipsa unei stipulații contrare, este obligat să restituie tot 10.000
                        EURO, indiferent de variația cursului BNR și de cotațiile EURO- RON (sau alte monede).
                    </p>

                    <p className="mt-5 text-lg">
                        În situația în care în contract nu a fost stabilit un termen de restituire al împrumutului ne
                        aflăm sub incidența art. 2162 Cod Civil, care stipulează: „Dacă nu a fost convenit un termen de
                        restituire, acesta va fi stabilit de instanţă, ţinându-se seama de scopul împrumutului, de
                        natura obligaţiei şi a bunurilor împrumutate, de situaţia părţilor şi de orice altă împrejurare
                        relevantă”, astfel că este necesar ca partea care dorește stabilirea unui termen de restituire
                        să se adreseze instanței de judecată cu o cerere prin care solicită fixarea unui termen de către
                        instanța investită, tocmai de aceea se preferă inserarea în contract a termenulu de restituire a
                        împrumutului.
                    </p>

                    <p className="mt-5 text-lg">
                        Dorim să atragem atenția asupra faptului că cele mai frecvente cauze de întrerupere sau chiar
                        anulare a unui contract de împrumut între persoane fizice le reprezintă clauzele abuzive pe care
                        părțile le stipulează în contract referitoare la dobânda percepută, iar art. 5 aliniatul (1) din
                        OG nr 13/2011 instituie o limitare a dobânzii convenționale si astfel aceasta nu poate depăși cu
                        mai mult de 50 % dobânda legala anuala, iar rata dobânzii legale se stabilește la rata dobânzii
                        de referință și politică monetară și ratele facilităților permanente a Băncii Naționale a
                        României, disponibilă pe site-ul www.bnr.ro
                    </p>

                    <p className="mt-5 text-lg">
                        O întrebare cu care ne-am confruntat des în ultima perioadă se referă la modalitatea de punere
                        în executare a unui contract de împrumut în situația în care debitorul nu își îndeplinește
                        obligația de restituire la scadență a sumei de bani împrumutate.
                        Pentru a putea răspunde la această întrebare trebuie să facem din nou trimitere la Codul Civil,
                        astfel că art. 2157 și art. 2165 stipulează că „în ceea ce priveşte obligaţia de restituire,
                        contractul de comodat (n.n. împrumut) încheiat în formă autentică sau printr-un înscris sub
                        semnătură privată cu dată certă constituie titlu executoriu, în condiţiile legii, în cazul
                        încetării prin decesul comodatarului sau prin expirarea termenului”, de unde reiese că pentru a
                        constitui titlu executoriu contractul de îmrumut trebuie încheiat fie la notar, fie în fața unui
                        avocat care poate conferi dată certă contractului, însă în ambele cazuri contractul trebuie să
                        respecte toate condițiile prevăzute de lege, inclusiv data restituirii împrumutului.
                    </p>

                    <p className="mt-5 text-lg">
                        Dacă totuși contractul de împrumut nu a fost încheiat în formă autentică și nici printr-un
                        înscris sub semnătură privită cu dată certă, părțile pot conveni chiar și ulterior să se
                        prezinte fie în fața unui notar pentru autentificarea contractului, fie în fața unui avocat
                        pentru conferirea datei certe pentru ca acel contract de împrumut să devină titlu executoriu.
                    </p>

                    <p className="mt-5 text-lg">
                        Cu toate acestea, în situția în care o parte contractantă refuză autentificarea contractului sau
                        să se prezinte în fața unui avocat pentru conferirea datei certe a contractului, pentru
                        executarea contractului și obligarea împrumutatului de a restitui la scadență suma de bani
                        împrumutată, celeilalte părți îi rămâne varianta să se adrese instanței de judecată cu o cerere
                        de chemare în judecată prin care să solicite obligarea împrumutatului de a restitui suma de bani
                        împrumutată și o eventuală dobândă.
                    </p>

                    <p className="mt-5 text-lg">
                        În situația în care v-ați confruntat cu astfel de situații și doriți să împărtățiți cu noi
                        experiența dvs. nu ezitați să completați formularul, iar un coleg vă va contacta în cel mai
                        scurt timp posibil.
                    </p>
                </div>

                <div className="h-5"></div>
            </div>
            <Footer></Footer>
        </div>
    )
}