import address from '../assets/address.png';
import email from '../assets/email.png';
import phone from '../assets/phone.png';
import {useContext, useEffect, useState} from "react";
import {changeRoute} from "./Sidebar";
import {useNavigate} from "react-router-dom";
import LANGUAGES from "../helpers/translate";
import {LanguageContext} from "../helpers/LanguageContext";

export default function Footer() {

    const [date, setDate] = useState(new Date())
    const updateDate = setInterval(() => {
        setDate(new Date())
        clearInterval(updateDate)
    }, 1000)

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    return (
        <div className="w-full bg-blue flex-col">
            <footer className="w-11/12 mx-auto text-gray-600 body-font sm:py-2 md:py-6">
                <div className="lg:container py-4 md:py-8 mx-auto flex items-center md:flex-row flex-col">
                    <span
                        className="w-10/12 text-center sm:text-left md:w-6/12 lg:w-4/12 text-white font-bold text-xl md:text-2xl">
                        {t.title_footer}
                    </span>
                    <span className="inline-flex items-center sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                        <button onClick={() => {
                            navigate('/contact')
                        }} className="hidden font-semibold md:flex bg-white color-blue px-2.5 py-2.5 text-white mr-8 rounded-xl">
                            {t.contact_us}
                        </button>
                        <a className="text-white">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                 className="w-7 h-7"
                                 viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a>
      <a className="ml-4 text-white">
        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-7 h-7"
             viewBox="0 0 24 24">
          <path
              d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
        </svg>
      </a>
      <a className="ml-4 text-white">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
             className="w-7 h-7" viewBox="0 0 24 24">
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
        </svg>
      </a>
      <a className="ml-4 text-white">
        <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"
             className="w-7 h-7" viewBox="0 0 24 24">
          <path stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
          <circle cx="4" cy="4" r="2" stroke="none"></circle>
        </svg>
      </a>
    </span>
                </div>
                <div className="lg:container mx-auto flex flex-col text-white flex-wrap">
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <div>
                            <ul className="flex flex-row space-x-3">
                                <li>
                                    <button onClick={() => navigate('/')}>{t.home}</button>
                                </li>
                                <li>
                                    <button onClick={() => navigate('/despre-noi')}>{t.about_us}</button>
                                </li>
                                <li>
                                    <button onClick={() => navigate('/contact')}>Contact</button>
                                </li>
                                <li className="w-36">
                                    <button onClick={() => navigate('/termeni-conditii')}>{t.terms_conditions}</button>
                                </li>
                            </ul>
                            <div className="">
                        <span
                            className="text-base text-[#9CA2A5]">{date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getSeconds() + " GMT"}</span>
                            </div>
                        </div>
                        <div
                            className="flex flex-wrap justify-center md:justify-end items-center px-8 gap-6 mt-6 md:mt-0">
                            <a href="https://www.google.com/maps/place/Strada+Mihai+Eminescu+3,+Oradea+410056/@47.0593407,21.9318008,17z/data=!3m1!4b1!4m6!3m5!1s0x474647e13080f601:0x4a89d684ef24fd!8m2!3d47.0593407!4d21.9343811!16s%2Fg%2F11j8v5gy3b?entry=ttu" target="_blank"
                               className="flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"/>
                                </svg>
                                <span className="ml-1.5">Mun. Oradea, str. Mihai Eminescu nr. 3, spațiu nr. 4, jud. Bihor</span>
                            </a>
                            <a href='tel:+4 (0) 743 121 814' className="flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                                </svg>

                                <span className="ml-1.5">+4 (0) 743 121 814</span>
                            </a>
                            <a href='mailto:office@moisepaul.ro' className="flex flex-row items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5"
                                    fill="#fff"
                                    version="1.1"
                                    viewBox="0 0 75.294 75.294"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        d="M66.097 12.089h-56.9C4.126 12.089 0 16.215 0 21.286v32.722c0 5.071 4.126 9.197 9.197 9.197h56.9c5.071 0 9.197-4.126 9.197-9.197V21.287c.001-5.072-4.125-9.198-9.197-9.198zm-4.494 6L37.647 33.523 13.691 18.089h47.912zm4.494 39.117h-56.9A3.201 3.201 0 016 54.009V21.457l29.796 19.16c.04.025.083.042.124.065.043.024.087.047.131.069.231.119.469.215.712.278.025.007.05.01.075.016.267.063.537.102.807.102h.006c.27 0 .54-.038.807-.102.025-.006.05-.009.075-.016.243-.063.48-.159.712-.278a3.27 3.27 0 00.131-.069c.041-.023.084-.04.124-.065l29.796-19.16v32.551a3.204 3.204 0 01-3.199 3.198z"></path>
                                </svg>
                                <span className="ml-1.5">office@moisepaul.ro</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="lg:container mx-auto lg:px-8 h-[0.5px] bg-orange w-11/12 my-5"></div>
                <div className="lg:container mx-auto flex justify-between items-center">
                    {/*<button className="hidden md:flex flex-col">*/}
                    {/*            <span className="text-2xl md:text-xl text-white font-cormorant"*/}
                    {/*                  onClick={() => changeRoute("/")}>Paul Moise</span>*/}
                    {/*    <span className="text-[10px] text-white uppercase font-bold"*/}
                    {/*          onClick={() => changeRoute("/")}>Cabinet avocatura</span>*/}
                    {/*</button>*/}
                    <div className="md:inline-1 flex justify-center md:justify-end mb-3">
                        <span className="text-[#9CA2A5]">© 2024 Dacodus Software • All Rights Reserved</span>
                    </div>
                </div>
            </footer>
        </div>
    )
}