import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import header_background from "../assets/header_background.jpg";
import frame_lines from "../components/svg/frame_lines.png"
import frame_dots_long from "../components/svg/frame_dots_long.png"
import blog1 from "../components/svg/blog1.png";
import header_about_us from "../assets/header_about_us.jpg";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {LanguageContext} from "../helpers/LanguageContext";
import LANGUAGES from "../helpers/translate";

export default function Blogs(){

    const categories = [
    //     {
    //     name: "Avocati",
    //     activated: true
    // }, {
    //     name: "Familie"
    // }, {
    //     name: "Afaceri"
    // }, {
    //     name: "Personal"
    // }
    ]

    const navigate = useNavigate()
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/blogs`, {
                headers: {
                    // Authorization: "Bearer " + getToken(),
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {
                console.log("result", result)
                // const new_blog = result.data;
                // setData([...data, new_blog]);
                // setAddModal(false);
                setBlogs(result.data.blogs)
            })
            .catch((err) => {});
    }, [])

    const { currentLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    return(
        <div>
            <Sidebar></Sidebar>
            {/* HEADER */}
            <div className="relative h-[400px] md:h-[400px] max-h-2xl w-full">
                <img src={header_about_us} alt="Background Image"
                     className="absolute inset-0 w-full h-full object-cover filter"/>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div
                    // style={{ backgroundImage: `url(${background})` }}
                    className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col items-center justify-center my-auto">
                    <img className="absolute h-10 md:h-12 top-24 right-0 md:right-6" src={frame_lines}/>
                    <div className="flex md:hidden mx-auto">
                        {/*<img*/}
                        {/*    className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                    <div className="w-full flex flex-col justify-center pt-12">
                        <h1 className="text-center text-3xl lg:text-4xl text-white font-bold">Blog</h1>
                        <span className='w-24 mx-auto bg-white h-1 mt-1.5 rounded-lg'/>
                        {/*<span*/}
                        {/*    className=" text-start text-base lg:text-lg text-white font-medium mt-4">Iata cateva informatii despre cine sunt noi</span>*/}
                    </div>
                    <div className="hidden lg:flex w-3/12">
                        {/*<img*/}
                        {/*    className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                </div>
            </div>
            <div className="w-full bg-gray-100">
                <div className="h-5"></div>

                <div className="w-11/12 mx-auto py-3 flex items-center">
                    {categories.map((category, i) => {
                        return <div
                            className={`border-[1.5px] border-[#0E1947] ${category.activated ? 'bg-blue text-white' : 'color-blue'} mr-2 rounded-xl px-3.5 py-2`}>
                            <span className="font-medium">{category.name}</span>
                        </div>
                    })}
                </div>

                <div
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 w-11/12 mx-auto -mx-4 -my-8 py-10">
                    {blogs.map((item, i) => {
                        return <div className="w-full bg-white rounded-2xl">
                            <div className="relative h-full flex flex-col items-start">
                                {/*<div className="absolute bg-[#0E1947] rounded-xl opacity-80 top-5 left-5">*/}
                                {/*    <h2 className="tracking-widest text-sm title-font font-medium text-white px-2.5 py-2">{item.category}</h2>*/}
                                {/*</div>*/}
                                <div className="w-full">
                                    <img
                                        className="w-full h-36 object-cover rounded-t-2xl overflow-hidden object-cover bg-cover bg-no-repeat"
                                        src={item.image}/>
                                </div>
                                <div className="flex-grow px-3 xl:px-4">
                                    <h1 className="title-font line-clamp-2 md:text-xl font-bold text-gray-900 mb-2 mt-3">{item.name}</h1>
                                    {/*<p className="line-clamp-3 mb-3">{item.description}</p>*/}
                                    <p dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                    }} className="line-clamp-3 mb-3"></p>
                                    <div className="flex flex-row justify-between mb-3">
                                      <span className="text-[#9CA2A5] text-[13px]">
                                          {item.date}
                                      </span>
                                        <a className="inline-1">
                                            <button onClick={() => {
                                                navigate(`/blog/${item.slug}`)
                                            }} className="flex-grow flex flex-col mr-4">
                                                <span className="title-font font-medium text-sm color-blue">{t.more}</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>

                <div className="h-5"></div>
            </div>
            <Footer></Footer>
        </div>
    )
}