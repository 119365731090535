import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import header_background from "../assets/header_background.jpg";
import frame_lines from "../components/svg/frame_lines.png"
import frame_dots_long from "../components/svg/frame_dots_long.png"
import header_about_us from "../assets/header_about_us.jpg";

export default function Terms() {
    return (
        <div>
            <Sidebar></Sidebar>
            {/* HEADER */}

            <div className="relative h-[350px] md:h-[400px] max-h-2xl w-full">
                <img src={header_about_us} alt="Background Image"
                     className="absolute inset-0 w-full h-full object-cover filter"/>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div
                    // style={{ backgroundImage: `url(${background})` }}
                    className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col items-center justify-center my-auto">
                    <img className="hidden lg:flex absolute h-10 md:h-12 top-24 right-0 md:right-6" src={frame_lines}/>
                    <div className="flex md:hidden mx-auto">
                        {/*<img*/}
                        {/*    className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                    <div className="w-full flex flex-col justify-center pt-12">
                        <h1 className="text-center text-3xl lg:text-4xl text-white font-bold">Termeni si conditii</h1>
                        <span className='w-24 mx-auto bg-white h-1 mt-1.5 rounded-lg'/>
                        {/*<span*/}
                        {/*    className=" text-start text-base lg:text-lg text-white font-medium mt-4">Iata cateva informatii despre cine sunt noi</span>*/}
                    </div>
                    <div className="hidden lg:flex w-3/12">
                        {/*<img*/}
                        {/*    className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                </div>
            </div>

            <div className="lg:w-11/12 mx-auto ">
                <div className="h-5"></div>

                <div className="w-11/12 mx-auto lg:container">
                    <p className="text-lg">
                        Folosirea/navigarea pe site-ul www.moisepaul.ro (denumit în continuare „Site”) de către
                        utilizatori
                        reprezintă acceptul termenilor și condițiilor de utilizare care guvernează relația dintre
                        utilizator și CABNIET DE AVOCATURĂ MOISE PAUL (denumită în continuare C.A.).
                    </p>

                    <p className="mt-5 text-lg">
                        C.A. a realizat acest Site ca sursă de informare, cu respectarea normelor legale în vigoare
                        privind drepturile de autor, confidențialitatea și a regulilor de publicitate prevăzute de Legea
                        nr. 51/1991 pentru organizarea și exercitarea profesiei de avocat si a Statutului profesiei de
                        avocat, conform ultimelor completări și modificări.

                    </p>

                    <p className="mt-5 text-lg">
                        Folosirea sau vizitarea Site-ului implică acceptarea de către utilizatori a termenilor și a
                        condițiilor descrise în cele ce urmează.
                    </p>

                    <p className="mt-5 text-lg">
                        În acest context, în situația în care considerați că aveți nevoie asistare sau reprezentare în
                        diferite situații, dar apreciați că datorită situației financiare nu vă permiteți un avocat, vă
                        rugăm să completați formularul pentru a putea fi contactat/ ă de către colegii noștri în vederea
                        obținerii unei programări la sediul cabinetului de avocatură unde vom discuta în condiții de
                        confidențialitate aceste toate aspectele.
                    </p>

                    <p className="font-bold text-lg mt-5">Limitarea răspunderii</p>

                    <p className="mt-5 text-lg">
                        C.A. va face toate eforturile rezonabile pentru a asigura acuratețea Site-ului și va face toate
                        diligențele pentru a corecta erorile și omisiunile cât mai repede posibil. Cu toate acestea,
                        C.A. nu este ținut responsabil pentru apariția oricăror inadvertențe, erori sau omisiuni ale
                        informațiilor furnizate. Utilizatorul acceptă în mod expres că C.A. nu va fi ținut responsabil
                        pentru nici un fel de daune directe/indirecte, minore/majore sau incidentale, incluzând, dar
                        fără a se limita însă la pierderi de profit, vad comercial sau alte bunuri incorporale,
                        rezultate din: utilizarea informațiilor de pe Site sau orice alt aspect în legătură cu acest
                        Site. Conținutul acestui Site are caracter informativ, nu reprezintă un mijloc de reclamă și nu
                        poate fi interpretat drept o ofertă de a contracta anumite servicii juridice.
                    </p>

                    <p className="mt-5 text-lg">
                        Utilizarea acestui Site și toate informațiile conținute, descărcate sau accesate de pe Site sunt
                        puse la dispoziție fără garanții de orice fel și fără a se urmări un anumit scop sau încălcarea
                        drepturilor unei a treia părți. C.A. nu va fi responsabilă pentru nici o problemă sau o
                        disfuncționalitate a rețelelor sau a liniilor telefonice, sistemelor de internet online,
                        serverelor, furnizorilor de acces pe internet, echipamentelor, calculatoarelor, programelor sau
                        a oricărui alt element ce poate cauza stricăciuni computerelor personale ca rezultat al
                        utilizării acestui Site.
                    </p>

                    <p className="mt-5 text-lg">
                        C.A. își rezervă dreptul să modifice și să interzică accesul sau să oprească temporar sau
                        permanent orice parte a acestui Site sau orice informație conținută în acesta, fără asumarea
                        obligației de a notifica utilizatorii. Aceste schimbări vor intra în vigoare din momentul
                        publicării lor pe acest Site.
                    </p>

                    <p className="text-lg mt-5">
                        Vizitatorii și utilizatorii acestui Site acceptă și sunt de acord să folosească informațiile pe
                        propriul lor risc. C.A. nu va fi responsabilă pentru orice fel de daune-interese directe,
                        indirecte, cauzate de utilizarea acestui Site.
                    </p>

                    <p className="text-lg mt-5">
                        Informațiile cuprinse în acest Site: (i) au doar un caracter general și nu sunt destinate să
                        abordeze circumstanțe specifice ale nici unei persoane sau entități; (ii) nu sunt exhaustive,
                        exacte sau actualizate; (iii) uneori fac trimitere la site-uri externe, asupra cărora C.A. nu
                        exercită nici un control și pentru care nu își asumă nici o responsabilitate; (iv) nu constituie
                        și nici nu poate substitui o consultație juridică de specialitate în orice domeniu de drept.

                    </p>

                    <p className="text-lg mt-5">
                        C.A. nu garantează și nu va garanta faptul că un document disponibil on-line reproduce exact un
                        text adoptat în mod oficial.

                    </p>

                    <p className="font-bold text-lg mt-5">
                        Politica de confidențialitate.
                    </p>

                    <p className="font-bold text-lg">
                        Prelucrarea datelor cu caracter personal
                    </p>

                    <p className="text-lg mt-5">
                        C.A. respectă dreptul la viață privată al persoanelor care vizitează Site-ul și care ne
                        contactează pe cale electronică. Atunci când interacționați cu noi, există posibilitatea să ne
                        furnizați informații care să ne ajute să vă identificăm (spre exemplu, nume, e-mail, număr de
                        telefon). De asemenea, anumite informații care ne ajută să vă identificăm sunt necesare pentru
                        executarea contractelor de asistență juridică pe care le încheiem cu dumneavoastră, dar și în
                        vederea transmiterii noutăților legislative și anunțurilor de participare la conferințe și alte
                        evenimente, sub forma unor newslettere. În plus, C.A. colectează unele informații referitoare la
                        colaboratorii și angajații săi, conform legii. Aceste informații sunt cunoscute sub denumirea de
                        „date cu caracter personal”. Prezenta Politică de confidențialitate respectă dispozițiile
                        Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016
                        privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter
                        personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE
                        (denumit în continuare „GDPR”).
                    </p>

                    <p className="font-bold text-lg mt-5">
                        Datele cu caracter personal ale minorilor
                    </p>

                    <p className="text-lg mt-5">
                        C.A. nu solicită și nu colectează în mod intenționat date personale de la minori. În situația în
                        care C.A. descoperă că a colectat în mod accidental date personale de la un minor, va elimina
                        acele date în cel mai scurt timp posibil. Totuși, C.A. va putea colecta date cu caracter
                        personal de la minori atunci când există consimțământul expres acordat în acest sens de către
                        părinți sau reprezentanții legali.
                    </p>

                    <p className="font-bold text-lg mt-5">
                        Newslettere și e-mail-uri
                    </p>

                    <p>
                        În cazul în care ne furnizați adresa dumneavoastră de e-mail (fie pentru că solicitați primirea
                        comunicărilor și noutăților noastre, prin abonarea voluntară la newsletter, fie pentru că
                        sunteți clientul societății noastre, sunteți în baza noastră de date ca urmare a unor
                        corespondențe anterioare, adresa dvs. de e-mail a fost selectată dintr-o bază de date la care
                        ați subscris ori adresa dvs. se regăsește pe site-urile publice), datele de contact pe care le
                        oferiți vor putea fi utilizate pentru a vă trimite newsletter-uri.
                    </p>

                    <p className="text-lg mt-5">
                        Putem folosi, de asemenea, adresa dumneavoastră de e-mail pentru a vă trimite comunicări
                        referitoare la conferințele și evenimentele pe care le organizăm (de exemplu, e-mail de
                        confirmare atunci când vă înscrieți pentru sau vă dezabonați de la o înregistrare sau activitate
                        specifică), precum și anumite servicii legate de anunțuri (de exemplu, anunțuri despre
                        update-uri la Politica noastră de confidențialitate, întreruperi sau schimbări la serviciile
                        noastre on-line sau politicile de suport tehnic sau alte modificări conexe).

                    </p>

                    <p className="font-bold text-lg mt-5">
                        Drepturile persoanelor vizate
                    </p>

                    <p className="text-lg mt-5">
                        În conformitate cu dispozițiile GDPR, beneficiați de următoarele drepturi:
                    </p>

                    <p className="text-lg mt-5">
                        A. Dreptul de acces, adică de a obține din partea C.A. o confirmare că se prelucrează sau nu
                        date cu caracter personal care vă privesc și, în caz afirmativ, acces la datele respective.

                    </p>

                    <p className="text-lg mt-5">
                        B. Dreptul la rectificare, adică dreptul de a obține de la C.A., fără întârzieri nejustificate,
                        rectificarea datelor cu caracter personal inexacte care vă privesc;

                    </p>

                    <p className="text-lg mt-5">
                        C. Dreptul la ștergerea datelor („dreptul de a fi uitat”), adică dreptul de a obține din partea
                        C.A. ștergerea datelor cu caracter personal care vă privesc, fără întârzieri nejustificate,
                        respectiv obligația C.A. de a șterge datele cu caracter personal fără întârzieri nejustificate,
                        în cazul în care există unul dintre următoarele motive:

                    </p>

                    <ul className='list-disc ml-10 text-lg mt-5'>
                        <li>
                            datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopurilor pentru care
                            au fost colectate sau prelucrate;
                        </li>
                        <li>
                            dumneavoastră vă retrageți consimțământul pe baza căruia are loc prelucrarea, în
                            conformitate cu art. 6 alin. (1) lit. a) sau cu art. 9 alin. (2) lit. a) din GDPR, și nu
                            există niciun alt temei juridic pentru prelucrare;

                        </li>
                        <li>
                            dumneavoastră vă opuneți prelucrării în temeiul art. 21 alin. (1) din GDPR și nu există
                            motive legitime care să prevaleze în ceea ce privește prelucrarea sau dumneavoastră vă
                            opuneți prelucrării în temeiul art. 21 alin. (2);
                        </li>
                        <li>
                            datele cu caracter personal au fost prelucrate ilegal;
                        </li>
                        <li>
                            datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale ce
                            revine C.A. în temeiul dreptului UE, sau al dreptului intern sub incidența căruia se află
                            societatea.
                        </li>
                    </ul>

                    <p className="text-lg mt-5">
                        D. Dreptul la restricționarea prelucrării, atunci când:
                    </p>

                    <ul className='list-disc ml-10 text-lg mt-5'>
                        <li>
                            dumneavoastră contestați exactitatea datelor, pentru perioada în care C.A. verifică
                            exactitatea datelor;

                        </li>
                        <li>
                            prelucrarea este ilegală, iar dumneavoastră vă opuneți ștergerii datelor cu caracter
                            personal, solicitând în schimb restricționarea utilizării lor;

                        </li>
                        <li>
                            C.A. nu mai are nevoie de datele cu caracter personal în scopul prelucrării, dar
                            dumneavoastră le solicitați pentru constatarea, exercitarea sau apărarea unui drept în
                            instanță;

                        </li>
                        <li>
                            dumneavoastră v-ați opus prelucrării în conformitate cu art. 21 alin. (1) GDPR, pentru
                            intervalul de timp în care se verifică dacă drepturile legitime ale operatorului prevalează
                            asupra celor ale persoanei vizate.

                        </li>
                    </ul>

                    <p className="text-lg mt-5">
                        E. Dreptul la portabilitatea datelor, adică dreptul de a primi datele cu caracter personal care
                        vă privesc și pe care le-ați furnizat C.A. într-un format structurat, utilizat în mod curent și
                        care poate fi citit automat și dreptul de a transmite aceste date altui operator, fără obstacole
                        din partea C.A., în cazul în care: prelucrarea se bazează pe consimțământ în temeiul art. 6
                        alin. (1) lit. a) sau al art. 9 alin. (2) lit. a) GDPR sau pe un contract în temeiul art. 6
                        alin. (1) lit. b) GDPR și prelucrarea este efectuată prin mijloace automate;
                    </p>

                    <p className="text-lg mt-5">
                        F. Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv
                        crearea de profiluri, care ar produce efecte juridice în privința dumneavoastră sau vă afectează
                        în mod similar într-o măsură semnificativă.
                    </p>

                    <p className="text-lg mt-5">
                        Pentru exercitarea efectivă a acestor drepturi, vă puteți adresa cu o cerere scrisă, datată și
                        semnată, la adresa de e-mail office@avocatmoise.ro, sau la sediul C.A.: Mun. Oradea, str. Mihai
                        Eminescu nr. 4, spațiu nr. 3, jud. Bihor, România.

                    </p>

                    <p className="text-lg mt-5">
                        De asemenea, vă este recunoscut dreptul de a vă adresa justiției.

                    </p>

                    <p>
                        Pe Site, utilizatorul este responsabil pentru toate activitățile care survin ca urmare a
                        furnizării voluntare a numelui, e-mailului și numărului de telefon al acestuia. C.A. nu poate fi
                        considerată responsabilă pentru erorile survenite din cauza neglijenței utilizatorului privind
                        securitatea și confidențialitatea numelui, e-mailului și numărului de telefon al acestuia.
                        Datele personale ale utilizatorului vor putea fi folosite de către C.A. și colaboratorii săi
                        numai în scopul declarat al acestui Site. Datele personale vor putea fi însă transmise
                        autorităților îndrituite să verifice tranzacțiile comerciale sau altor autorități în drept să
                        efectueze orice verificări justificate în baza legii, dacă acest lucru va fi solicitat în
                        conformitate cu reglementările în vigoare.

                    </p>

                    <p className="font-bold text-lg mt-5">
                        Tehnologiile utilizate. Politica modulelor de tip cookie
                    </p>

                    <p className="font-bold text-lg mt-5">
                        Ultima actualizare: mai 2021.
                    </p>

                    <p className="text-lg mt-5">
                        A. Definiție. Modulele “cookie” sunt fișiere text de mici dimensiuni, formate din litere şi
                        cifre, care sunt amplasate în memoria dispozitivului pe care dumneavoastră îl folosiți pentru a
                        accesa website-ul nostru (de exemplu, calculatorul, telefonul, tableta ș.a.). În aceste fișiere
                        sunt stocate anumite informații (de exemplu, setările tehnice ale website-ului sau limba
                        preferată) care ne pot fi retransmise la următoarea vizită a website-ului. Informațiile
                        colectate de modulele “cookie” pe care le folosim nu rețin numele, datele pe care dumneavoastră
                        le completați în formulare sau alte date personale, dar utilizează un identificator (ID) pentru
                        a putea face distincția între utilizatori.
                    </p>

                    <p className="text-lg mt-5">
                        B. De ce folosim module “cookie”? Website- C.A. instalează module “cookie” doar pentru a permite
                        îmbunătățirea funcțiilor oferite, astfel încât experiența de navigare a website-ului să fie una
                        cât mai plăcută. Nu utilizăm, însă, module cookie analitice pentru a culege, a prelucra sau a
                        difuza date cu caracter personal în scopuri de marketing sau promovare. Modulele “cookie” pe
                        care le utilizăm sunt necesare pentru a putea asigura funcționarea website-ului în condiții
                        optime.
                    </p>

                    <p className="text-lg mt-5">
                        C. Modificarea prezentei politici. C.A. își rezervă dreptul de a modifica oricând prezenta
                        politică referitoare la aspectele tehnice privind accesarea website-ului și politica privind
                        utilizarea modulelor „cookie”.
                    </p>

                    <p className="text-lg font-bold mt-5">
                        Transferul datelor cu caracter personal
                    </p>

                    <p>
                        C.A. nu va divulga niciodată datele dumneavoastră cu caracter personal unei societăți comerciale
                        sau organizații terțe care intenționează să le folosească în scopuri de marketing direct, cu
                        excepția situației în care v-ați oferit consimțământul expres și prealabil în acest sens.

                    </p>

                    <p>
                        Din rațiuni strict tehnice, datele dumneavoastră ar putea fi dezvăluite unor colaboratori
                        externi care își desfășoară activitatea în domeniul IT&C (spre exemplu, unui furnizor de soluții
                        software pentru găzduirea Site-ului, ori în vederea transmiterii de newsletter-uri din partea
                        C.A.). În aceste cazuri ne vom asigura că partenerii menționați respectă securitatea și
                        confidențialitatea informațiilor dumneavoastră.

                    </p>

                    <p>
                        Am putea dezvălui informațiile dumneavoastră personale, în cazul în care ne este solicitat acest
                        lucru prin lege, sau în cazul în care, în opinia noastră, fundamentată pe buna-credință, o
                        astfel de acțiune este în mod rezonabil necesară pentru a ne conforma procedurilor legale,
                        pentru a răspunde oricăror solicitări și pretenții, sau pentru a proteja siguranța sau
                        drepturile C.A., ale clienților săi, ori ale publicului în general.
                    </p>

                    <p>
                        C.A. nu transferă datele cu caracter personal ale persoanelor vizate în afara Spațiului Economic
                        European.

                    </p>

                    <p className="font-bold text-lg mt-5">
                        Securitatea datelor
                    </p>

                    <p className="text-lg mt-5">
                        C.A. ia toate măsurile organizatorice și tehnice necesare pentru a proteja confidențialitatea și
                        securitatea informațiilor dumneavoastră personale colectate de pe Site, dar și din alte surse,
                        inclusiv în cazul categoriilor speciale de date cu caracter personal și informațiilor personale
                        colectate de la minori.
                    </p>

                    <p className="font-bold text-lg mt-5">
                        Cum ne puteți contacta?
                    </p>

                    <p className="text-lg mt-5">
                        Dacă aveți orice întrebări, reclamații, sau comentarii cu privire la această politică de
                        confidențialitate sau la practicile noastre de colectare a informațiilor, vă rugăm să ne
                        contactați la office@avocatmoise.ro sau la sediul CABINETULUI DE AVOCATURĂ MOISE PAUL: Mun.
                        Oradea, str. Mihai Eminescu nr. 4, spațiu nr. 3, jud. Bihor.
                    </p>

                    <p className="font-bold text-lg mt-5">
                        Drepturi de autor
                    </p>

                    <p className="text-lg mt-5">
                        © Copyright 2024 www.moisepaul.ro are toate drepturile rezervate.
                    </p>

                    <p className="text-lg mt-5">
                        Conținutul Site-ului este protejat de legile dreptului de autor. Toate materialele (incluzând,
                        fără limitări, toate articolele, textele, pozele, logo-urile și design-ul) conținute în acest
                        Site sunt protejate de legile dreptului de autor și nu pot fi reproduse, distribuite, transmise
                        sau publicate fără acordul expres scris al C.A. Site-ul este proprietatea C.A. Utilizatorii se
                        obligă să nu copieze texte, porțiuni de texte sau grafică pentru folosirea acestora în scopuri
                        comerciale.
                    </p>

                    <p className="text-lg mt-5">
                        Este strict interzisă utilizarea acestui Site în scopul distrugerii, întreruperii sau alterării
                        lui, a conținutului sau securității acestuia, sau pentru a discredita/hărțui membrii C.A.,
                        clienții săi și/sau serviciile oferite.
                    </p>

                    <p className="text-lg mt-5">
                        Actualizat in Noiembrie 2024
                    </p>

                </div>

                <div className="h-5"></div>
            </div>
            <Footer></Footer>
        </div>
    )
}