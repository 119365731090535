import Sidebar from "../components/Sidebar";
import header_1 from "../components/svg/header_1.png";
import icon1 from "../components/svg/icon1.svg";
import icon2 from "../components/svg/icon2.svg";
import video from "../components/svg/video.png";
import text from "../components/svg/text.jpg";
import Footer from "../components/Footer";
import {useContext, useEffect} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";
import blog1 from "../components/svg/blog1.png";
import {useNavigate} from "react-router-dom";
import bg_photo_2 from "../assets/building_2.jpg";
import header_about_us from "../assets/header_about_us.jpg";
import dots from "../components/svg/dots.png";
import frame_lines from "../components/svg/frame_lines.png";
import {LanguageContext} from "../helpers/LanguageContext";
import LANGUAGES from "../helpers/translate";

export function About() {

    const navigate = useNavigate()

    function changeRoute(page) {
        navigate(page)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    const FAQS_TRANSLATIONS = {
        ro: [{
            title: "Drept comercial",
            description: "Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:",
            options: [{
                name: 'consultanță juridică și elaborarea de opinii juridice în diverse probleme sau litigii ce se ivesc în relațiile dintre profesioniști;'
            }, {
                name: 'asigurarea asistenței specializate în vederea redactării și încheierii diverselor contracte civile;'
            }, {
                name: 'asigurarea asistenței specializate privind înființarea societăților comerciale, inclusiv în vederea deschiderii de sucursale, filiale sau puncte de lucru;'
            }, {
                name: 'reprezentarea în fața Oficiului Registrului Comerțului;'
            }, {
                name: 'asistarea/ reprezentarea societăților comerciale la negocieri cu diverși parteneri;'
            }, {
                name: 'asistarea/ reprezentarea societăților comerciale în fața autorităților publice, notarilor publici și a instanțelor judecătorești și/ sau arbitrale;'
            }, {
                name: 'redactarea și susținerea acțiunilor civile în fața instanțelor de judecată sau arbitrale;'
            }, {
                name: 'consultanță juridică referitoare la Regulamentul privind Procedura Europeană A Somației De Plată\n'
            }, {
                name: 'executarea hotărârilor judecătorești;'
            }]
        }, {
            title: "Drept societar",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'asigurarea asistenței specializate privind înființarea societăților comerciale, inclusiv în vederea deschiderii de sucursale, filiale sau puncte de lucru;\n'
            }, {
                name: 'reprezentarea în fața Oficiului Registrului Comerțului;\n'
            }, {
                name: 'organizarea adunărilor generale ale asociaților/ acționarilor;\n'
            }, {
                name: 'asistarea/ reprezentarea în cadrul adunărilor generale ale asociaților/ acționarilor;\n'
            }, {
                name: 'operațiuni de majorare/ diminuare de capital social al societăților comerciale;\n'
            }, {
                name: 'redactarea de hotărâri ale adunării generale ale asociaților/ acționarilor;\n'
            }]
        }, {
            title: "Contencios administrativ si fiscal",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'consultanță  juridică de specialitate în vederea în vederea încheierii contractelor cu autoritățile publice'
            }, {
                name: 'consultanță juridică de specialitatea în vederea modificării sau încetării contractelor administrative'
            }, {
                name: 'asistarea/ reprezentarea în fața autorităților publice în procedura prealabilă declanșării litigiilor'
            }, {
                name: 'asistarea/ reprezentarea în fața instanțelor de contencios administrativ și fiscal în litigii având ca temei juridic Legea 554/2004 privind contencios administrativ și fiscal'
            }, {
                name: 'consultanță privind legislația referitoare la TVA și la alte taxe și impozite'
            }, {
                name: 'consultanță privind acordarea de facilităților fiscale optime'
            }]
        }, {
            title: "Dreptul asigurarilor",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'consultanță juridică de specialitate în vederea încheierii contractelor de asigurarea în diverse domenii'
            }, {
                name: 'consultanță juridică de specialitate în vederea modificării sau încetării contractelor de asigurare'
            }, {
                name: 'asistarea/ reprezentarea în vederea negocierii clauzelor contractuale privind asigurările'
            }, {
                name: 'asistarea/ reprezentarea în procedura prealabilă declanșării litigiilor împotriva asigurătorilor'
            }, {
                name: 'asistarea/ reprezentarea în instanță în litigii generate de raporturile de asigurare'
            }, {
                name: 'asistarea/ reprezentarea în instanță în cadrul litigiilor privind obținerea daunelor interese și recuperarea prejudiciului în cazul accidentelor auto soldate cu victime'
            }]
        }, {
            title: "Dreptul contraventional",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în litigiile specifice dreptului contravențional'
            }, {
                name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în acțiuni având ca obiect anularea proceselor de constatare și sancționare a contravențiilor'
            }, {
                name: 'asistarea reprezentarea în fața instanțelor judecătorești în litigii împotriva diverselor instituții ale statului'
            }]
        }, {
            title: "Dreptul transportului",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'consultanță juridică de specialitate în vederea elaborării contractelor specifice domeniului transportului'
            }, {
                name: 'consultanță juridică de specialitate în vederea modificării sau încetării contractelor de transport'
            }, {
                name: 'asistarea/ reprezentarea în cadrul negocierilor cu diverși parteneri în vederea obținerii celor mai avantajoase soluții'
            }, {
                name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în litigii având ca obiect pretențiile ce decurg din desfășurarea relațiilor contractuale de transport'
            }]
        }],
        en: [
            {
                title: "Commercial Law",
                description: "Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;",
                options: [{
                    name: 'Legal advice and drafting legal opinions on various issues or disputes arising in professional relations;'
                }, {
                    name: 'Providing specialized assistance in drafting and concluding various civil contracts;'
                }, {
                    name: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;'
                }, {
                    name: 'Representation before the Trade Registry Office;'
                }, {
                    name: 'Assisting/representing companies in negotiations with various partners;'
                }, {
                    name: 'Assisting/representing companies before public authorities, public notaries, and courts of law and/or arbitration;'
                }, {
                    name: 'Drafting and supporting civil actions before courts of law or arbitration;'
                }, {
                    name: 'Legal advice regarding the Regulation on the European Payment Order Procedure'
                }, {
                    name: 'Execution of court decisions;'
                }]
            }, {
                title: "Corporate Law",
                description: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;',
                options: [{
                    name: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;'
                }, {
                    name: 'Representation before the Trade Registry Office;'
                }, {
                    name: 'Organizing general meetings of shareholders/associates;'
                }, {
                    name: 'Assisting/representing in general meetings of shareholders/associates;'
                }, {
                    name: 'Operations for increasing/decreasing the share capital of companies;'
                }, {
                    name: 'Drafting resolutions of the general meetings of shareholders/associates;'
                }]
            }, {
                title: "Administrative and Tax Litigation",
                description: 'Specialized legal advice for concluding contracts with public authorities;',
                options: [{
                    name: 'Specialized legal advice for concluding contracts with public authorities;'
                }, {
                    name: 'Specialized legal advice for amending or terminating administrative contracts;'
                }, {
                    name: 'Assisting/representing before public authorities in the preliminary procedures before initiating litigation;'
                }, {
                    name: 'Assisting/representing in administrative and tax litigation courts for cases governed by Law 554/2004 on administrative and tax disputes;'
                }, {
                    name: 'Advice on legislation related to VAT and other taxes;'
                }, {
                    name: 'Advice on granting optimal tax facilities;'
                }]
            }, {
                title: "Insurance Law",
                description: 'Specialized legal advice for concluding insurance contracts in various fields;',
                options: [{
                    name: 'Specialized legal advice for concluding insurance contracts in various fields;'
                }, {
                    name: 'Specialized legal advice for amending or terminating insurance contracts;'
                }, {
                    name: 'Assisting/representing in negotiating contractual clauses related to insurance;'
                }, {
                    name: 'Assisting/representing in preliminary procedures before initiating litigation against insurers;'
                }, {
                    name: 'Assisting/representing in court for disputes arising from insurance relationships;'
                }, {
                    name: 'Assisting/representing in court for disputes regarding compensation claims and damage recovery in cases of car accidents involving victims;'
                }]
            }, {
                title: "Contraventional Law",
                description: 'Assisting/representing in court for disputes specific to contraventional law;',
                options: [{
                    name: 'Assisting/representing in court for disputes specific to contraventional law;'
                }, {
                    name: 'Assisting/representing in court for actions aimed at annulling minutes of finding and sanctioning contraventions;'
                }, {
                    name: 'Assisting/representing in court for disputes against various state institutions;'
                }]
            }, {
                title: "Transport Law",
                description: 'Specialized legal advice for drafting contracts specific to the transport sector;',
                options: [{
                    name: 'Specialized legal advice for drafting contracts specific to the transport sector;'
                }, {
                    name: 'Specialized legal advice for amending or terminating transport contracts;'
                }, {
                    name: 'Assisting/representing in negotiations with various partners to obtain the most advantageous solutions;'
                }, {
                    name: 'Assisting/representing in court for disputes concerning claims arising from the performance of transport contractual relationships;'
                }]
            }
        ],
    };

    const faqs = FAQS_TRANSLATIONS[currentLanguage];

    return (
        <div className="App">
            <Sidebar/>

            <div className="relative h-[400px] md:h-[400px] max-h-2xl w-full">
                <img src={header_about_us} alt="Background Image"
                     className="absolute inset-0 w-full h-full object-cover filter"/>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div
                    // style={{ backgroundImage: `url(${background})` }}
                    className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col items-center justify-center my-auto">
                    <img className="absolute h-10 md:h-12 top-24 right-0 md:right-6" src={frame_lines}/>
                    <div className="flex md:hidden mx-auto">
                        {/*<img*/}
                        {/*    className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                    <div className="w-full flex flex-col justify-center pt-12">
                        <h1 className="text-center text-3xl lg:text-4xl text-white font-bold">{t.about_us}</h1>
                        <span className='w-24 mx-auto bg-white h-1 mt-1.5 rounded-lg'/>
                        {/*<span*/}
                        {/*    className=" text-start text-base lg:text-lg text-white font-medium mt-4">Iata cateva informatii despre cine sunt noi</span>*/}
                    </div>
                    <div className="hidden lg:flex w-3/12">
                        {/*<img*/}
                        {/*    className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                </div>
            </div>

            <section className="bg-gray-100">
                <div className="w-11/12 mx-auto flex flex-col-reverse lg:flex-row items-center justify-center py-9">
                    <div className={currentLanguage === "ro" ? 'lg:w-8/12 mx-auto flex flex-col' : 'hidden'}>
                        <span
                            className="text-3xl font-bold mt-6 md:mt-0">Despre Noi - Excelență și Integritate în Domeniul Avocaturii</span>
                        <span className="text-[16px] mt-6 ">Bine ați venit la biroul nostru de avocatură, unde excelența, integritatea și dedicarea sunt fundamentele pe care ne construim activitatea zilnică. Suntem o echipă de avocați experimentați și pasionați, dedicați să oferim soluții juridice inovatoare și eficiente, adaptate nevoilor specifice ale fiecărui client.</span>

                        <span className="text-lg mt-6 font-bold">Misiunea Noastră</span>
                        <span className="lg:w-9/12 text-sm mt-4">Misiunea noastră este să oferim servicii juridice de cea mai înaltă calitate, asigurându-ne că drepturile și interesele clienților noștri sunt protejate și promovate în mod eficient. Ne angajăm să fim un partener de încredere în fiecare etapă a procesului juridic, oferind consiliere strategică și reprezentare profesionistă.</span>

                        <span className="text-lg mt-6 font-bold">Valorile Noastre</span>

                        <ul className="mt-4">
                            <li className="cursor-pointer">
                                1. <span className="font-bold color-blue">Profesionalism</span> - Ne străduim să
                                menținem cele mai
                                înalte standarde de profesionalism în tot ceea ce facem. Fiecare caz este tratat cu
                                maximă atenție și seriozitate, asigurându-ne că fiecare client beneficiază de cea mai
                                bună reprezentare juridică posibilă.
                            </li>
                            <li className="mt-2.5">
                                2. <span className="font-bold color-blue">Integritate</span> - Credem că integritatea
                                este piatra
                                de temelie a oricărei relații de succes. Ne conducem activitatea cu onestitate și
                                transparență, respectând atât legea, cât și valorile etice ale profesiei noastre.
                            </li>
                            <li className="mt-2.5">
                                3. <span className="font-bold color-blue">Inovație</span> - Într-o lume juridică în
                                continuă
                                schimbare, ne mândrim cu abilitatea noastră de a gândi creativ și de a găsi soluții
                                inovatoare la problemele juridice complexe. Investim constant în formarea și dezvoltarea
                                noastră pentru a rămâne la curent cu cele mai noi tendințe și practici din domeniu.
                            </li>
                            <li className="mt-2.5">
                                4. <span className="font-bold color-blue">Empatie</span> - Înțelegem că fiecare client
                                este unic și
                                că fiecare caz are propriile sale nuanțe. Ascultăm cu atenție și empatizăm cu clienții
                                noștri, asigurându-ne că soluțiile oferite sunt personalizate și adecvate nevoilor lor
                                specifice.
                            </li>
                        </ul>

                    </div>
                    <div className={currentLanguage === "en" ? "lg:w-8/12 mx-auto flex flex-col" : 'hidden'}>
    <span className="text-3xl font-bold mt-6 md:mt-0">
        About Us - Excellence and Integrity in the Legal Field
    </span>
                        <span className="text-[16px] mt-6">
        Welcome to our law office, where excellence, integrity, and dedication form the foundation of our daily work.
        We are a team of experienced and passionate lawyers committed to providing innovative and effective legal
        solutions tailored to the specific needs of each client.
    </span>

                        <span className="text-lg mt-6 font-bold">Our Mission</span>
                        <span className="lg:w-9/12 text-sm mt-4">
        Our mission is to deliver top-quality legal services, ensuring that our clients' rights and interests are
        effectively protected and promoted. We strive to be a trusted partner at every stage of the legal process,
        offering strategic advice and professional representation.
    </span>

                        <span className="text-lg mt-6 font-bold">Our Values</span>

                        <ul className="mt-4">
                            <li className="cursor-pointer">
                                1. <span className="font-bold color-blue">Professionalism</span> - We strive to uphold
                                the highest standards of
                                professionalism in everything we do. Each case is handled with the utmost care and
                                seriousness, ensuring that
                                every client receives the best possible legal representation.
                            </li>
                            <li className="mt-2.5">
                                2. <span className="font-bold color-blue">Integrity</span> - We believe integrity is the
                                cornerstone of any
                                successful relationship. We conduct our work with honesty and transparency, respecting
                                both the law and the
                                ethical values of our profession.
                            </li>
                            <li className="mt-2.5">
                                3. <span className="font-bold color-blue">Innovation</span> - In a constantly evolving
                                legal landscape, we take
                                pride in our ability to think creatively and find innovative solutions to complex legal
                                issues. We continually
                                invest in our training and development to stay up-to-date with the latest trends and
                                practices in the field.
                            </li>
                            <li className="mt-2.5">
                                4. <span className="font-bold color-blue">Empathy</span> - We understand that each
                                client is unique, and every
                                case has its own nuances. We listen carefully and empathize with our clients, ensuring
                                that the solutions we
                                provide are personalized and tailored to their specific needs.
                            </li>
                        </ul>
                    </div>

                    <div>
                        <img
                            className="w-9/12 sm:w-7/12 md:w-10/12 mx-auto max-h-[45rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"
                            src={text}/>
                    </div>
                </div>
            </section>

            <section className='relative bg-gray-100 pt-5'>
                {/*<div>*/}
                {/*    <img alt='Dots' className="h-10 md:h-12 absolute top-1 right-5" src={dots}/>*/}
                {/*</div>*/}
                <div className="flex flex-col justify-center">
                    <span className="text-3xl text-center font-bold color-blue">{t.areas_of_activity}</span>
                    <div className='w-24 mx-auto bg-blue h-1 mt-1 rounded-lg'/>
                    <span className="w-10/12 mx-auto text-center text-base lg:text-lg text-[#373642] font-medium mt-4">
                      {t.p_areas_of_activity}
                  </span>
                </div>
                <div className=" relative w-full">
                    <Swiper
                        style={{
                            "--swiper-pagination-color": "#124E66",
                            "--swiper-pagination-bullet-inactive-color": "#b8b8bb",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "12px",
                            "--swiper-pagination-bullet-horizontal-gap": "6px"
                        }}
                        slidesPerView="1"
                        mousewheel={true}
                        // direction="vertical"
                        modules={[Pagination, Autoplay]}
                        pagination={{clickable: true}}
                        scrollbar={{draggable: true}}
                        // speed={550}
                        // autoplay={{
                        //     delay: 4500,
                        //     disableOnInteraction: false,
                        // }}
                        className="mySwiper xl:container flex items-center mx-auto h-full max-h-[750px] md:max-h-[500px] mt-[50px] pt-16"
                    >
                        {faqs.map((item, i) => {
                            return <SwiperSlide className="md:w-10/12 xl:w-11/12 mx-auto pb-12">
                                <div
                                    className="w-full px-4 flex flex-col md:flex-row items-center justify-center my-auto pt-5 xl:pt-10">
                                    {/*<div className="image-container flex md:hidden mx-auto w-8/12">*/}
                                    {/*    <img*/}
                                    {/*        className="lg:w-full h-full max-h-[15em] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                                    {/*        src={blog1}/>*/}
                                    {/*</div>*/}
                                    <div className="w-11/12 lg:w-6/12 flex flex-col justify-start pt-7">
                                        <span className="md:w-8/12 text-start text-3xl lg:text-4xl font-bold">{item.title}</span>
                                        <span className="md:w-10/12 text-start text-base lg:text-lg font-medium mt-4">{item.description}</span>
                                        <button onClick={() => changeRoute(`/domenii/activitate?item=${i}`)}
                                                className="w-[9rem] font-semibold flex items-center justify-center bg-blue text-[16px] text-white rounded-xl py-2 px-2 mt-6">
                                            <span className="">{t.more}</span>
                                        </button>
                                    </div>
                                    <div className="hidden image-container lg:flex w-4/12">
                                        <img
                                            className="lg:w-full mx-auto max-h-[39rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"
                                            src={blog1}/>
                                    </div>
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </section>

            <Footer/>

        </div>
    )
}