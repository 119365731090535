import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import './css/index.css';
import HomePage from './pages/HomePage';
import GeneralActivity from './pages/GeneralActivity';
import reportWebVitals from './reportWebVitals';
import Contact from "./pages/Contact";
import DomeniiActivitate from "./pages/DomeniiActivitate";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import {About} from "./pages/About";
import ProBono from "./pages/ProBono";
import LoanContract from "./pages/LoanContract";
import Terms from "./pages/Terms";
import AdminRoute from "./helpers/AdminRoute";
import AD_Dashboard from "./pages/admin/AD_Dashboard";
import AD_Press from "./pages/admin/AD_Press";
import AD_BlogDetails from "./pages/admin/AD_BlogDetails";
import AD_Gallery from "./pages/admin/AD_Gallery";
import Login from "./pages/admin/Login";
import LanguageProvider from "./helpers/LanguageContext"


let id = document.location.href.substr(27)

export function openBlog(id) {
    window.open("/blog/" + id, "_self")
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <LanguageProvider>
        <Router>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/general" element={<GeneralActivity/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/domenii/activitate" element={<DomeniiActivitate/>}/>
                <Route path="/blogs" element={<Blogs/>}/>
                <Route path={"/blog/:blogId"} element={<Blog/>}/>
                <Route path={"/despre-noi/"} element={<About/>}/>
                <Route path={"/blogs"} element={<Blogs/>}/>
                <Route path={"/pro-bono"} element={<ProBono/>}/>
                <Route path={"/contract-imprumut"} element={<LoanContract/>}/>
                <Route path={"/termeni-conditii"} element={<Terms/>}/>
                <Route path={"/login"} element={<Login/>}/>

                <Route path="/admin/dashboard" element={< AdminRoute/>}>
                    <Route path="/admin/dashboard" element={<AD_Dashboard/>}/>
                </Route>
                <Route path="/admin/press" element={< AdminRoute/>}>
                    <Route path="/admin/press" element={<AD_Press/>}/>
                </Route>
                <Route path="/admin/blog/:id" element={< AdminRoute/>}>
                    <Route path="/admin/blog/:id" element={<AD_BlogDetails/>}/>
                </Route>
                <Route path="/admin/gallery" element={< AdminRoute/>}>
                    <Route path="/admin/gallery" element={<AD_Gallery/>}/>
                </Route>

            </Routes>


        </Router>
    </LanguageProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
