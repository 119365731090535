import Footer from "../components/Footer";
import Sidebar, {changeRoute} from "../components/Sidebar";
import dots_orange from "../components/svg/dots_orange.svg"
import lawyer from "../components/svg/lawyer.jpg"
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import useEffectAsync from "../helpers/request";
import {LanguageContext} from "../helpers/LanguageContext";
import LANGUAGES from "../helpers/translate";

export default function Blog(){

    const {blogId} = useParams();

    const [blog, setBlog] = useState(null);
    const [blogs, setBlogs] = useState([]);

    useEffectAsync(async () => {
         const result = await axios
             .get(`${process.env.REACT_APP_API_URL}/blog/${blogId}`, {
                 headers: {
                     // Authorization: "Bearer " + getToken(),
                     "Content-Type": "multipart/form-data",
                 },
             })
        setBlog(result.data?.blog)
        setBlogs(result.data?.blogs)

         return () => {}
    }, [blogId])

    const navigate = useNavigate()

    const { currentLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    return(
        <div>
            <Sidebar></Sidebar>
            <div className="h-20"></div>
            <div className="lg:container mx-auto relative w-full px-4 flex flex-row">
                <div className="lg:w-7/12 2xl:w-9/12 pt-10">
                    <span className="text-3xl font-bold ">{blog?.title}</span>
                    <div className="flex flex-row items-center pt-4">
                        {/*<div className="bg-blue px-3 py-2.5 rounded-xl">*/}
                        {/*    <span className="text-white">Avocatura</span>*/}
                        {/*</div>*/}
                        <div className="flex flex-row ml-3">
                            <span>{blog?.date}</span>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:flex absolute top-5 right-0 justify-end">
                    <img className="w-[27rem]" src={dots_orange} />
                </div>
            </div>

            <div className="lg:container w-full mx-auto flex flex-row pt-6 md:pt-10 px-4 pb-10">
                <div className="flex flex-col w-full lg:w-9/12 mx-auto">
                    <img
                        className="min-w-full object-cover w-full max-w-2xl max-h-[25rem] lg:max-h-[30rem] mx-auto rounded-2xl"
                        src={blog?.image}/>

                    <div className="flex flex-col">
                    <span dangerouslySetInnerHTML={{
                        __html: blog?.description,
                    }} className="mt-6">
                        {/*Ce este acest text ?*/}
                    </span>
                    </div>
                </div>
                <div className="hidden w-4/12 lg:block lg:w-3/12 px-3">
                    <h1 className="font-bold text-2xl px-4">{t.other_articles}</h1>

                    {blogs.map((item, i) => {
                        return <button onClick={() => {
                            navigate(`/blog/${item.slug}`)
                        }}>
                            <div className="w-full h-0.5 bg-gray-300 mx-4"></div>

                            <div className="flex flex-col mt-6 rounded-xl py-3 duration-200 hover:bg-gray-300 px-4">
                        <span className="line-clamp-2" dangerouslySetInnerHTML={{
                            __html: item?.description,
                        }}>
                        </span>
                                <div className="flex justify-between">
                                    {/*<div className="mt-2.5">*/}
                                    {/*    <span className="text-white bg-blue rounded-lg px-2.5 py-1">item?.title</span>*/}
                                    {/*</div>*/}
                                    <div className="w-full flex justify-end items-center">
                                <span className="text-gray-400">
                                    {item.date}
                                </span>
                                    </div>
                                </div>
                            </div>
                        </button>
                    })}
                </div>
            </div>

            <Footer></Footer>
        </div>
    )
}