import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import header_background from "../assets/header_background.jpg";
import frame_lines from "../components/svg/frame_lines.png"
import frame_dots_long from "../components/svg/frame_dots_long.png"
import {useContext, useState} from "react";
import {Disclosure} from "@headlessui/react";
import DisclosureItem from "../components/DisclosureItem";
import header_about_us from "../assets/header_about_us.jpg";
import {LanguageContext} from "../helpers/LanguageContext";
import LANGUAGES from "../helpers/translate";

export default function DomeniiActivitate(){

    const queryParams = new URLSearchParams(window.location.search)
    const getItem = queryParams.get("item") ? parseFloat(queryParams.get("item")) : undefined

    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    const FAQS_TRANSLATIONS = {
        ro: [{
            title: "Drept comercial",
            description: "Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:",
            options: [{
                name: 'consultanță juridică și elaborarea de opinii juridice în diverse probleme sau litigii ce se ivesc în relațiile dintre profesioniști;'
            }, {
                name: 'asigurarea asistenței specializate în vederea redactării și încheierii diverselor contracte civile;'
            }, {
                name: 'asigurarea asistenței specializate privind înființarea societăților comerciale, inclusiv în vederea deschiderii de sucursale, filiale sau puncte de lucru;'
            }, {
                name: 'reprezentarea în fața Oficiului Registrului Comerțului;'
            }, {
                name: 'asistarea/ reprezentarea societăților comerciale la negocieri cu diverși parteneri;'
            }, {
                name: 'asistarea/ reprezentarea societăților comerciale în fața autorităților publice, notarilor publici și a instanțelor judecătorești și/ sau arbitrale;'
            }, {
                name: 'redactarea și susținerea acțiunilor civile în fața instanțelor de judecată sau arbitrale;'
            }, {
                name: 'consultanță juridică referitoare la Regulamentul privind Procedura Europeană A Somației De Plată\n'
            }, {
                name: 'executarea hotărârilor judecătorești;'
            }]
        }, {
            title: "Drept societar",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'asigurarea asistenței specializate privind înființarea societăților comerciale, inclusiv în vederea deschiderii de sucursale, filiale sau puncte de lucru;\n'
            }, {
                name: 'reprezentarea în fața Oficiului Registrului Comerțului;\n'
            }, {
                name: 'organizarea adunărilor generale ale asociaților/ acționarilor;\n'
            }, {
                name: 'asistarea/ reprezentarea în cadrul adunărilor generale ale asociaților/ acționarilor;\n'
            }, {
                name: 'operațiuni de majorare/ diminuare de capital social al societăților comerciale;\n'
            }, {
                name: 'redactarea de hotărâri ale adunării generale ale asociaților/ acționarilor;\n'
            }]
        }, {
            title: "Contencios administrativ si fiscal",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'consultanță  juridică de specialitate în vederea în vederea încheierii contractelor cu autoritățile publice'
            }, {
                name: 'consultanță juridică de specialitatea în vederea modificării sau încetării contractelor administrative'
            }, {
                name: 'asistarea/ reprezentarea în fața autorităților publice în procedura prealabilă declanșării litigiilor'
            }, {
                name: 'asistarea/ reprezentarea în fața instanțelor de contencios administrativ și fiscal în litigii având ca temei juridic Legea 554/2004 privind contencios administrativ și fiscal'
            }, {
                name: 'consultanță privind legislația referitoare la TVA și la alte taxe și impozite'
            }, {
                name: 'consultanță privind acordarea de facilităților fiscale optime'
            }]
        }, {
            title: "Dreptul asigurarilor",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'consultanță juridică de specialitate în vederea încheierii contractelor de asigurarea în diverse domenii'
            }, {
                name: 'consultanță juridică de specialitate în vederea modificării sau încetării contractelor de asigurare'
            }, {
                name: 'asistarea/ reprezentarea în vederea negocierii clauzelor contractuale privind asigurările'
            }, {
                name: 'asistarea/ reprezentarea în procedura prealabilă declanșării litigiilor împotriva asigurătorilor'
            }, {
                name: 'asistarea/ reprezentarea în instanță în litigii generate de raporturile de asigurare'
            }, {
                name: 'asistarea/ reprezentarea în instanță în cadrul litigiilor privind obținerea daunelor interese și recuperarea prejudiciului în cazul accidentelor auto soldate cu victime'
            }]
        }, {
            title: "Dreptul contraventional",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în litigiile specifice dreptului contravențional'
            }, {
                name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în acțiuni având ca obiect anularea proceselor de constatare și sancționare a contravențiilor'
            }, {
                name: 'asistarea reprezentarea în fața instanțelor judecătorești în litigii împotriva diverselor instituții ale statului'
            }]
        }, {
            title: "Dreptul transportului",
            description: 'Cabinetul de avocatură, prin avocați specializați vă oferă consultanță, asistare și reprezentare în vederea obținerii celor mai bune rezultate în domenii privind:',
            options: [{
                name: 'consultanță juridică de specialitate în vederea elaborării contractelor specifice domeniului transportului'
            }, {
                name: 'consultanță juridică de specialitate în vederea modificării sau încetării contractelor de transport'
            }, {
                name: 'asistarea/ reprezentarea în cadrul negocierilor cu diverși parteneri în vederea obținerii celor mai avantajoase soluții'
            }, {
                name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în litigii având ca obiect pretențiile ce decurg din desfășurarea relațiilor contractuale de transport'
            }]
        }],
        en: [
            {
                title: "Commercial Law",
                description: "Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;",
                options: [{
                    name: 'Legal advice and drafting legal opinions on various issues or disputes arising in professional relations;'
                }, {
                    name: 'Providing specialized assistance in drafting and concluding various civil contracts;'
                }, {
                    name: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;'
                }, {
                    name: 'Representation before the Trade Registry Office;'
                }, {
                    name: 'Assisting/representing companies in negotiations with various partners;'
                }, {
                    name: 'Assisting/representing companies before public authorities, public notaries, and courts of law and/or arbitration;'
                }, {
                    name: 'Drafting and supporting civil actions before courts of law or arbitration;'
                }, {
                    name: 'Legal advice regarding the Regulation on the European Payment Order Procedure'
                }, {
                    name: 'Execution of court decisions;'
                }]
            }, {
                title: "Corporate Law",
                description: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;',
                options: [{
                    name: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;'
                }, {
                    name: 'Representation before the Trade Registry Office;'
                }, {
                    name: 'Organizing general meetings of shareholders/associates;'
                }, {
                    name: 'Assisting/representing in general meetings of shareholders/associates;'
                }, {
                    name: 'Operations for increasing/decreasing the share capital of companies;'
                }, {
                    name: 'Drafting resolutions of the general meetings of shareholders/associates;'
                }]
            }, {
                title: "Administrative and Tax Litigation",
                description: 'Specialized legal advice for concluding contracts with public authorities;',
                options: [{
                    name: 'Specialized legal advice for concluding contracts with public authorities;'
                }, {
                    name: 'Specialized legal advice for amending or terminating administrative contracts;'
                }, {
                    name: 'Assisting/representing before public authorities in the preliminary procedures before initiating litigation;'
                }, {
                    name: 'Assisting/representing in administrative and tax litigation courts for cases governed by Law 554/2004 on administrative and tax disputes;'
                }, {
                    name: 'Advice on legislation related to VAT and other taxes;'
                }, {
                    name: 'Advice on granting optimal tax facilities;'
                }]
            }, {
                title: "Insurance Law",
                description: 'Specialized legal advice for concluding insurance contracts in various fields;',
                options: [{
                    name: 'Specialized legal advice for concluding insurance contracts in various fields;'
                }, {
                    name: 'Specialized legal advice for amending or terminating insurance contracts;'
                }, {
                    name: 'Assisting/representing in negotiating contractual clauses related to insurance;'
                }, {
                    name: 'Assisting/representing in preliminary procedures before initiating litigation against insurers;'
                }, {
                    name: 'Assisting/representing in court for disputes arising from insurance relationships;'
                }, {
                    name: 'Assisting/representing in court for disputes regarding compensation claims and damage recovery in cases of car accidents involving victims;'
                }]
            }, {
                title: "Contraventional Law",
                description: 'Assisting/representing in court for disputes specific to contraventional law;',
                options: [{
                    name: 'Assisting/representing in court for disputes specific to contraventional law;'
                }, {
                    name: 'Assisting/representing in court for actions aimed at annulling minutes of finding and sanctioning contraventions;'
                }, {
                    name: 'Assisting/representing in court for disputes against various state institutions;'
                }]
            }, {
                title: "Transport Law",
                description: 'Specialized legal advice for drafting contracts specific to the transport sector;',
                options: [{
                    name: 'Specialized legal advice for drafting contracts specific to the transport sector;'
                }, {
                    name: 'Specialized legal advice for amending or terminating transport contracts;'
                }, {
                    name: 'Assisting/representing in negotiations with various partners to obtain the most advantageous solutions;'
                }, {
                    name: 'Assisting/representing in court for disputes concerning claims arising from the performance of transport contractual relationships;'
                }]
            }
        ],
    };

    const faqs = FAQS_TRANSLATIONS[currentLanguage];

    const [openIndex, setOpenIndex] = useState(getItem);

    const handleDisclosureClick = (index) => {
        setOpenIndex(index === openIndex ? null : index);
    };

    return(
        <div>
            <Sidebar></Sidebar>
            {/* HEADER */}
            {/*<div className="w-full bg-blue">*/}
            {/*    <div className="relative w-11/12 mx-auto py-24 flex justify-center items-center">*/}
            {/*        <div className="pt-14">*/}
            {/*            <img className="absolute h-10 md:h-12 top-20 right-0 md:right-6" src={frame_lines}/>*/}
            {/*            <span className="text-3xl text-white text-center font-bold">Domenii de activitate</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="relative h-[350px] md:h-[400px] max-h-2xl w-full">
                <img src={header_about_us} alt="Background Image"
                     className="absolute inset-0 w-full h-full object-cover filter"/>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div
                    // style={{ backgroundImage: `url(${background})` }}
                    className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col items-center justify-center my-auto">
                    <img className="hidden lg:flex absolute h-10 md:h-12 top-24 right-0 md:right-6" src={frame_lines}/>
                    <div className="flex md:hidden mx-auto">
                        {/*<img*/}
                        {/*    className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                    <div className="w-full flex flex-col justify-center pt-12">
                        <h1 className="text-center text-3xl lg:text-4xl text-white font-bold">{t.areas_of_activity}</h1>
                        <span className='w-24 mx-auto bg-white h-1 mt-1.5 rounded-lg'/>
                        <span
                            className="w-10/12 mx-auto text-center text-base lg:text-lg text-white font-medium mt-4">{t.p_areas_of_activity}</span>
                    </div>
                    <div className="hidden lg:flex w-3/12">
                        {/*<img*/}
                        {/*    className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                </div>
            </div>

            <div className="w-full bg-gray-100">

                <div className="xl:container mx-auto p-6">
                    <div className="grid md:grid-cols-2 gap-4">

                        {faqs.map((item, i) => {
                            return <DisclosureItem isOpen={openIndex === i} title={item.title}
                                                   description={item.description}
                                                   onClick={() => handleDisclosureClick(i)} options={item.options}/>
                        })}

                    </div>
                </div>

            </div>
            <Footer></Footer>
        </div>
    )
}