import '../css/App.css';
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Sliders from "../components/Sliders";
import divider from "../assets/divider.svg"
import frame_dots from "../components/svg/frame_dots.svg"
import dots from "../components/svg/dots.png"
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper/modules";
import header_background from "../assets/header_background.jpg";
// import 'swiper/css';
import header_1 from "../assets/header_1.png"
import blog1 from "../components/svg/blog1.png"
import photo1 from "../components/svg/header_photo_1.png"
import icon1 from "../components/svg/icon1.svg"
import icon2 from "../components/svg/icon2.svg"
import frame_line_blue from "../components/svg/frame_line_blue.svg"
import icon_project_1 from "../components/svg/icon_project_1.svg"
import icon_project_2 from "../components/svg/icon_project_2.svg"
import icon_project_3 from "../components/svg/icon_project_3.svg"
import img_about_lawyer from "../components/svg/about_lawyer.png"
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import bg_photo_1 from "../assets/building.jpg"
import bg_photo_2 from "../assets/building_2.jpg"
import law_people_1 from "../components/svg/law_people_1.png"
import law_people_2 from "../components/svg/law_people_2.png"
import law_contract from "../components/svg/law_contract.png"
import law_activity from "../components/svg/law_activity.png"
import axios from "axios"
import LANGUAGES from "../helpers/translate";
import {LanguageContext } from "../helpers/LanguageContext";

function HomePage() {
    const navigate = useNavigate()
    const [blogs, setBlogs] = useState([])

    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    const FAQS_TRANSLATIONS = {
        ro: [
            {
                title: "Drept comercial",
                description: "Consultanță juridică și elaborarea de opinii juridice în diverse probleme sau litigii ce se ivesc în relațiile dintre profesioniști;",
                options: [{
                    name: 'consultanță juridică și elaborarea de opinii juridice în diverse probleme sau litigii ce se ivesc în relațiile dintre profesioniști;'
                }, {
                    name: 'asigurarea asistenței specializate în vederea redactării și încheierii diverselor contracte civile;'
                }, {
                    name: 'asigurarea asistenței specializate privind înființarea societăților comerciale, inclusiv în vederea deschiderii de sucursale, filiale sau puncte de lucru;'
                }, {
                    name: 'reprezentarea în fața Oficiului Registrului Comerțului;'
                }, {
                    name: 'asistarea/ reprezentarea societăților comerciale la negocieri cu diverși parteneri;'
                }, {
                    name: 'asistarea/ reprezentarea societăților comerciale în fața autorităților publice, notarilor publici și a instanțelor judecătorești și/ sau arbitrale;'
                }, {
                    name: 'redactarea și susținerea acțiunilor civile în fața instanțelor de judecată sau arbitrale;'
                }, {
                    name: 'consultanță juridică referitoare la Regulamentul privind Procedura Europeană A Somației De Plată\n'
                }, {
                    name: 'executarea hotărârilor judecătorești;'
                }]
            }, {
                title: "Drept societar",
                description: 'Asigurarea asistenței specializate privind înființarea societăților comerciale, inclusiv în vederea deschiderii de sucursale, filiale sau puncte de lucru;',
                options: [{
                    name: 'asigurarea asistenței specializate privind înființarea societăților comerciale, inclusiv în vederea deschiderii de sucursale, filiale sau puncte de lucru;'
                }, {
                    name: 'reprezentarea în fața Oficiului Registrului Comerțului;\n'
                }, {
                    name: 'organizarea adunărilor generale ale asociaților/ acționarilor;\n'
                }, {
                    name: 'asistarea/ reprezentarea în cadrul adunărilor generale ale asociaților/ acționarilor;\n'
                }, {
                    name: 'operațiuni de majorare/ diminuare de capital social al societăților comerciale;\n'
                }, {
                    name: 'redactarea de hotărâri ale adunării generale ale asociaților/ acționarilor;\n'
                }]
            }, {
                title: "Contencios administrativ si fiscal",
                description: 'Consultanță  juridică de specialitate în vederea în vederea încheierii contractelor cu autoritățile publice',
                options: [{
                    name: 'consultanță  juridică de specialitate în vederea în vederea încheierii contractelor cu autoritățile publice'
                }, {
                    name: 'consultanță juridică de specialitatea în vederea modificării sau încetării contractelor administrative'
                }, {
                    name: 'asistarea/ reprezentarea în fața autorităților publice în procedura prealabilă declanșării litigiilor'
                }, {
                    name: 'asistarea/ reprezentarea în fața instanțelor de contencios administrativ și fiscal în litigii având ca temei juridic Legea 554/2004 privind contencios administrativ și fiscal'
                }, {
                    name: 'consultanță privind legislația referitoare la TVA și la alte taxe și impozite'
                }, {
                    name: 'consultanță privind acordarea de facilităților fiscale optime'
                }]
            }, {
                title: "Dreptul asigurarilor",
                description: 'Consultanță juridică de specialitate în vederea încheierii contractelor de asigurarea în diverse domenii',
                options: [{
                    name: 'consultanță juridică de specialitate în vederea încheierii contractelor de asigurarea în diverse domenii'
                }, {
                    name: 'consultanță juridică de specialitate în vederea modificării sau încetării contractelor de asigurare'
                }, {
                    name: 'asistarea/ reprezentarea în vederea negocierii clauzelor contractuale privind asigurările'
                }, {
                    name: 'asistarea/ reprezentarea în procedura prealabilă declanșării litigiilor împotriva asigurătorilor'
                }, {
                    name: 'asistarea/ reprezentarea în instanță în litigii generate de raporturile de asigurare'
                }, {
                    name: 'asistarea/ reprezentarea în instanță în cadrul litigiilor privind obținerea daunelor interese și recuperarea prejudiciului în cazul accidentelor auto soldate cu victime'
                }]
            }, {
                title: "Dreptul contraventional",
                description: 'asistarea/ reprezentarea în fața instanțelor judecătorești în litigiile specifice dreptului contravențional',
                options: [{
                    name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în litigiile specifice dreptului contravențional'
                }, {
                    name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în acțiuni având ca obiect anularea proceselor de constatare și sancționare a contravențiilor'
                }, {
                    name: 'asistarea reprezentarea în fața instanțelor judecătorești în litigii împotriva diverselor instituții ale statului'
                }]
            }, {
                title: "Dreptul transportului",
                description: 'Consultanță juridică de specialitate în vederea elaborării contractelor specifice domeniului transportului',
                options: [{
                    name: 'consultanță juridică de specialitate în vederea elaborării contractelor specifice domeniului transportului'
                }, {
                    name: 'consultanță juridică de specialitate în vederea modificării sau încetării contractelor de transport'
                }, {
                    name: 'asistarea/ reprezentarea în cadrul negocierilor cu diverși parteneri în vederea obținerii celor mai avantajoase soluții'
                }, {
                    name: 'asistarea/ reprezentarea în fața instanțelor judecătorești în litigii având ca obiect pretențiile ce decurg din desfășurarea relațiilor contractuale de transport'
                }]
            },
        ],
        en: [
            {
                title: "Commercial Law",
                description: "Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;",
                options: [{
                    name: 'Legal advice and drafting legal opinions on various issues or disputes arising in professional relations;'
                }, {
                    name: 'Providing specialized assistance in drafting and concluding various civil contracts;'
                }, {
                    name: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;'
                }, {
                    name: 'Representation before the Trade Registry Office;'
                }, {
                    name: 'Assisting/representing companies in negotiations with various partners;'
                }, {
                    name: 'Assisting/representing companies before public authorities, public notaries, and courts of law and/or arbitration;'
                }, {
                    name: 'Drafting and supporting civil actions before courts of law or arbitration;'
                }, {
                    name: 'Legal advice regarding the Regulation on the European Payment Order Procedure'
                }, {
                    name: 'Execution of court decisions;'
                }]
            }, {
                title: "Corporate Law",
                description: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;',
                options: [{
                    name: 'Providing specialized assistance regarding the establishment of companies, including the opening of branches, subsidiaries, or work points;'
                }, {
                    name: 'Representation before the Trade Registry Office;'
                }, {
                    name: 'Organizing general meetings of shareholders/associates;'
                }, {
                    name: 'Assisting/representing in general meetings of shareholders/associates;'
                }, {
                    name: 'Operations for increasing/decreasing the share capital of companies;'
                }, {
                    name: 'Drafting resolutions of the general meetings of shareholders/associates;'
                }]
            }, {
                title: "Administrative and Tax Litigation",
                description: 'Specialized legal advice for concluding contracts with public authorities;',
                options: [{
                    name: 'Specialized legal advice for concluding contracts with public authorities;'
                }, {
                    name: 'Specialized legal advice for amending or terminating administrative contracts;'
                }, {
                    name: 'Assisting/representing before public authorities in the preliminary procedures before initiating litigation;'
                }, {
                    name: 'Assisting/representing in administrative and tax litigation courts for cases governed by Law 554/2004 on administrative and tax disputes;'
                }, {
                    name: 'Advice on legislation related to VAT and other taxes;'
                }, {
                    name: 'Advice on granting optimal tax facilities;'
                }]
            }, {
                title: "Insurance Law",
                description: 'Specialized legal advice for concluding insurance contracts in various fields;',
                options: [{
                    name: 'Specialized legal advice for concluding insurance contracts in various fields;'
                }, {
                    name: 'Specialized legal advice for amending or terminating insurance contracts;'
                }, {
                    name: 'Assisting/representing in negotiating contractual clauses related to insurance;'
                }, {
                    name: 'Assisting/representing in preliminary procedures before initiating litigation against insurers;'
                }, {
                    name: 'Assisting/representing in court for disputes arising from insurance relationships;'
                }, {
                    name: 'Assisting/representing in court for disputes regarding compensation claims and damage recovery in cases of car accidents involving victims;'
                }]
            }, {
                title: "Contraventional Law",
                description: 'Assisting/representing in court for disputes specific to contraventional law;',
                options: [{
                    name: 'Assisting/representing in court for disputes specific to contraventional law;'
                }, {
                    name: 'Assisting/representing in court for actions aimed at annulling minutes of finding and sanctioning contraventions;'
                }, {
                    name: 'Assisting/representing in court for disputes against various state institutions;'
                }]
            }, {
                title: "Transport Law",
                description: 'Specialized legal advice for drafting contracts specific to the transport sector;',
                options: [{
                    name: 'Specialized legal advice for drafting contracts specific to the transport sector;'
                }, {
                    name: 'Specialized legal advice for amending or terminating transport contracts;'
                }, {
                    name: 'Assisting/representing in negotiations with various partners to obtain the most advantageous solutions;'
                }, {
                    name: 'Assisting/representing in court for disputes concerning claims arising from the performance of transport contractual relationships;'
                }]
            }
        ],
    };

    const faqs = FAQS_TRANSLATIONS[currentLanguage];

    const SLIDE_TRANSLATIONS = {
        ro: [
            {
                title: "Soluții Juridice Eficiente și Siguranță Financiară",
                description: "Construim parteneriate solide cu societățile comerciale, oferind soluții juridice eficiente menite să prevină și să gestioneze inclusiv situațiile generate de întârzierea plăților, reducând astfel riscurile financiare și asigurând stabilitatea și sustenabilitatea afacerii.",
                bg_right: law_people_1,
                bg_header: bg_photo_1
            }, {
                title: "Suntem aici pentru obiectivul tau",
                description: 'Experimentați o creștere a activităților dvs. cu consultanța noastră de specialitate. Excelem în adaptarea strategiilor de succes la obiectivele dumneavoastră unice, acoperind domenii diverse pentru un impact maxim, profesional și încântător.',
                bg_right: law_people_2,
                bg_header: bg_photo_2
            }
        ],
        en: [
            {
                title: "Efficient Legal Solutions and Financial Security",
                description: "We build solid partnerships with commercial companies, offering effective legal solutions designed to prevent and manage situations caused by late payments, thus reducing financial risks and ensuring business stability and sustainability.",
                bg_right: law_people_1,
                bg_header: bg_photo_1
            }, {
                title: "We are here for your goal",
                description: 'Experience growth in your business with our expert consulting. We excel at tailoring successful strategies to your unique goals, covering diverse areas for maximum, professional and delightful impact.',
                bg_right: law_people_2,
                bg_header: bg_photo_2
            }
        ],
    };

    const slides = SLIDE_TRANSLATIONS[currentLanguage]; // Selectăm traducerile pe baza limbii

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function changeRoute(page) {
        navigate(page)
    }

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/blogs`, {
                headers: {
                    // Authorization: "Bearer " + getToken(),
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {
                console.log("result", result)
                // const new_blog = result.data;
                // setData([...data, new_blog]);
                // setAddModal(false);
                setBlogs(result.data.blogs)
            })
            .catch((err) => {});
    }, [])

  return (
      <div className="App">
          <Sidebar/>

          {/*<Sliders/>*/}

          <div className="pt-5 relative w-full bg-white">
              <Swiper
                  style={{
                      "--swiper-pagination-color": "#ffffff",
                      "--swiper-pagination-bullet-inactive-color": "#b8b8bb",
                      "--swiper-pagination-bullet-inactive-opacity": "1",
                      "--swiper-pagination-bullet-size": "12px",
                      "--swiper-pagination-bullet-horizontal-gap": "6px"
                  }}
                  slidesPerView="1"
                  mousewheel={true}
                  // direction="vertical"
                  modules={[Pagination, Autoplay]}
                  pagination={{clickable: true}}
                  scrollbar={{draggable: true}}
                  speed={550}
                  autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                  }}
                  className="mySwiper  flex items-center mx-auto h-full max-h-[750px] md:max-h-[500px] mt-[50px] -pt-24"
              >
                  {slides.map((item, i) => {
                      return <SwiperSlide
                          className={`md:w-10/12 xl:w-11/12 mx-auto 2xl:pb-24 object-cover bg-cover bg-no-repeat before:opacity-70 bg-[url(${bg_photo_1}`}>
                          <div className="relative h-[600px] md:h-[500px] max-h-2xl w-full">
                              <img src={item.bg_header} alt="Background Image"
                                   className="absolute inset-0 w-full h-full object-cover filter"/>
                              <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                              <div
                                  // style={{ backgroundImage: `url(${background})` }}
                                  className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col md:flex-row items-center justify-center my-auto">
                                  <div className="flex md:hidden mx-auto">
                                      <img
                                          className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"
                                          src={item.bg_right}/>
                                  </div>
                                  <div className="w-11/12 lg:w-6/12 flex flex-col justify-start pt-7">
                                      <span className="md:w-8/12 text-start text-3xl lg:text-4xl text-white font-bold">{item.title}</span>
                                      <span
                                          className="md:w-10/12 text-start text-base lg:text-lg text-white font-medium mt-4">{item.description}</span>
                                      {/*<button*/}
                                      {/*    className="w-[9rem] font-semibold flex items-center justify-center bg-blue text-[16px] text-white rounded-xl py-2 px-2 mt-6">*/}
                                      {/*    <span className="">Aflați mai multe</span>*/}
                                      {/*</button>*/}
                                  </div>
                                  <div className="hidden lg:flex w-3/12">
                                      <img
                                          className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"
                                          src={item.bg_right}/>
                                  </div>
                              </div>
                          </div>
                      </SwiperSlide>
                  })}
              </Swiper>
          </div>

          <div className="bg-blue w-full">
              <div
                  className="flex flex-col md:flex-row items-center justify-center w-11/12 lg:w-8/12 mx-auto py-4 md:py-6">
                  <span
                      className="ml-3.5 md:ml-2.5 text-white text-center">{t.title_banner}
                  </span>
                  <div className="animate-bounce ml-2 mt-3">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 text-white"
                          version="1"
                          viewBox="0 0 512 512"
                          fill="#fff"
                      >
                          <path
                              d="M2410 4794c-79-9-228-38-285-54-586-169-1014-637-1137-1243-23-111-23-118-23-932 0-756 1-828 19-920 69-373 266-715 544-946 218-181 458-296 735-351 160-32 434-32 593 0 662 132 1157 634 1280 1297 18 92 19 163 19 915s-1 823-19 915c-122 660-617 1164-1271 1295-97 20-372 34-455 24zm420-343c329-75 610-267 797-546 84-126 153-292 189-455 18-79 19-141 19-890s-1-811-19-890c-36-163-105-329-189-455-56-83-77-108-170-203-170-172-395-294-647-348-126-27-392-25-516 4-511 120-878 492-990 1002-18 79-19 141-19 890s1 811 19 890c74 340 268 627 551 817 153 103 375 185 560 206 87 10 330-3 415-22z"
                              transform="matrix(.1 0 0 -.1 0 512)"
                          ></path>
                          <path
                              d="M2495 4146c-41-18-83-69-90-109-3-18-5-190-3-384 3-337 4-353 24-379 39-53 71-69 134-69s95 16 134 69c20 27 21 39 21 406s-1 379-21 406c-11 15-32 37-46 47-33 25-113 32-153 13z"
                              transform="matrix(.1 0 0 -.1 0 512)"
                          ></path>
                      </svg>
                  </div>
              </div>
          </div>

          {/* bg-[#023F30]  */}
          <div className="w-full z-20 relative mt-28">
              <div className="lg:container mx-auto pb-10">

                  {/**/}
                  <section className="text-gray-600 body-font">
                      <div className="relative container px-5 py-4 mx-auto">
                          <div>
                              <img className="h-8 md:h-10 absolute -top-12 right-0" src={frame_dots}/>
                          </div>
                          <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                   className="inline-block w-8 h-8 text-[#100F1B] mb-8" viewBox="0 0 975.036 975.036">
                                  <path
                                      d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"/>
                              </svg>
                              <p className=" text-lg">{t.quote}</p>
                              <span className="inline-block h-1 w-12 rounded bg-[#100F1B] mt-8 mb-6"></span>
                              <h2 className="text-gray-900 font-medium title-font tracking-wider text-md">Janet Reno
                              </h2>
                              {/*<p className="text-gray-500">Senior Product Designer</p>*/}
                          </div>
                      </div>
                  </section>
              </div>
          </div>

          <section className="bg-blue ">
              <div
                  className="w-11/12 lg:container mx-auto flex flex-col-reverse md:flex-row items-center justify-center py-9">
                  <div className="md:w-8/12 mx-auto flex flex-col">
                      <span
                          className="text-3xl font-bold text-white mt-6 md:mt-0">{t.benefits}</span>
                      <span className="lg:w-9/12 text-white text-sm mt-4">{t.sub_title_benefits}</span>

                      <div className="flex flex-row w-full lg:w-10/12 bg-white rounded-r-2xl mt-6">
                          <img className="ml-5 w-20 h-20 lg:w-28 lg:h-28 my-auto" src={icon1}/>
                          <div className="flex flex-col px-4 py-3">
                              <span className="text-xl font-bold">{t.progressively_oriented}</span>
                              <span className="text-sm mt-1 md:mt-2.5">{t.subtitle_progressively_oriented}</span>
                          </div>
                      </div>
                      <div className="flex flex-row w-full lg:w-10/12 bg-white rounded-r-2xl mt-6">
                          <img className="ml-5 w-20 h-20 lg:w-28 lg:h-28 my-auto" src={icon2}/>
                          <div className="flex flex-col px-4 py-3">
                              <span className="text-xl font-bold">{t.innovative_vision}</span>
                              <span className="text-sm mt-1 lg:mt-2.5">{t.subtitle_innovative_vision}</span>
                          </div>
                      </div>
                  </div>
                  <div>
                      <img
                          className="w-9/12 sm:w-7/12 md:w-10/12 mx-auto max-h-[45rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"
                          src={law_contract}/>
                  </div>
              </div>
          </section>

          {/*  BLOGS */}
          <div className="relative bg-gray-100">
              <div>
                  <img alt='Dots' className="h-10 md:h-12 absolute top-10 right-5" src={dots}/>
              </div>

              <div className="lg:container flex flex-row w-11/12 mx-auto pt-24">
                  <div className="w-full md:w-7/12 flex flex-col">
                      <span className="font-bold text-2xl">{t.title_blogs}</span>
                      <span className="w-11/12">{t.subtitle_blogs}</span>
                  </div>
                  <div className="w-8/12 hidden md:flex justify-end items-center">
                      <button onClick={() => navigate('/blogs')}
                              className="justify-center color-blue border border-[#193340] py-2.5 px-3.5 rounded-xl">
                          <span className="font-semibold">{t.more_blogs}</span>
                      </button>
                  </div>
              </div>

              <div
                  className="lg:container bg-gray-100 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 w-11/12 mx-auto -mx-4 -my-8 py-10 mt-2">
                  {blogs.map((item, i) => {
                      return <div className="w-full bg-white rounded-2xl">
                          <div className="relative h-full flex flex-col items-start">
                              {/*<div className="absolute bg-[#0E1947] rounded-xl opacity-80 top-5 left-5">*/}
                              {/*    <h2 className="tracking-widest text-sm title-font font-medium text-white px-2.5 py-2">{item.category}</h2>*/}
                              {/*</div>*/}
                              <div className="w-full">
                                  <img
                                      className="w-full h-36 rounded-t-2xl overflow-hidden object-cover bg-cover bg-no-repeat"
                                      src={item.image}/>
                              </div>
                              <div className="flex-grow px-3 xl:px-4">
                                  <h1 className="title-font line-clamp-2 md:text-xl font-bold text-gray-900 mb-2 mt-3">{item.title}</h1>
                                  <p dangerouslySetInnerHTML={{
                                      __html: item?.description,
                                  }} className="line-clamp-3 mb-3"></p>
                                  <div className="flex flex-row justify-between mb-3">
                                      <span className="text-[#9CA2A5] text-[13px]">
                                          {item.date}
                                      </span>
                                      <a className="inline-1">
                                          <button onClick={() => {
                                              navigate(`/blog/${item.slug}`)
                                          }} className="flex-grow flex flex-col mr-4">
                                              <span className="title-font font-medium text-sm color-blue">{t.read_more}</span>
                                          </button>
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  })}
              </div>
              <div className="md:hidden w-11/12 mx-auto">
                  <button onClick={() => navigate('/blogs')}
                          className="w-full color-blue rounded-xl border-[#193340] border py-2 px-2.5 mt-6">
                      {t.more_blogs}
                  </button>
              </div>
          </div>

          {/* ABOUT LAWYER */}
          <section className="bg-blue">
              <div
                  className="w-11/12 lg:container mx-auto flex flex-col-reverse md:flex-row items-center justify-center mt-8 py-9">
                  <div className="md:w-8/12 mx-auto flex flex-col">
                      <span
                          className="text-3xl font-bold text-white mt-6 md:mt-0">{t.general_activity}</span>
                      <span className="lg:w-9/12 text-white text-sm mt-4">{t.subtitle_general_activity}</span>
                      <span className="lg:w-9/12 text-white text-sm mt-4">{t.p_general_activity}</span>

                      <div className="flex flex-row w-full lg:w-10/12 rounded-r-2xl mt-6">
                          <div className="flex items-center justify-start">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6"
                                  viewBox="0 0 24 24"
                              >
                                  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                      <g fillRule="nonzero" transform="translate(-528 -288)">
                                          <g transform="translate(528 288)">
                                              <path
                                                  fillRule="nonzero"
                                                  d="M24 0v24H0V0h24zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018zm.265-.113l-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022zm-.715.002a.023.023 0 00-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092z"
                                              ></path>
                                              <path
                                                  fill="#E6DED1"
                                                  d="M5.5 17a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-13-6.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM5.5 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM12 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
                                              ></path>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <div className="flex flex-col px-4 py-3 text-white">
                              <span className="text-base">{t.first_general_activity}</span>
                          </div>
                      </div>

                      <div className="flex flex-row w-full lg:w-10/12 rounded-r-2xl">
                          <div className="flex items-center justify-start">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6"
                                  viewBox="0 0 24 24"
                              >
                                  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                      <g fillRule="nonzero" transform="translate(-528 -288)">
                                          <g transform="translate(528 288)">
                                              <path
                                                  fillRule="nonzero"
                                                  d="M24 0v24H0V0h24zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018zm.265-.113l-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022zm-.715.002a.023.023 0 00-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092z"
                                              ></path>
                                              <path
                                                  fill="#E6DED1"
                                                  d="M5.5 17a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-13-6.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM5.5 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM12 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
                                              ></path>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <div className="flex flex-col px-4 py-3 text-white">
                              <span className="text-base">{t.two_general_activity}</span>
                          </div>
                      </div>
                      <div className="flex flex-row w-full lg:w-10/12 rounded-r-2xl">
                          <div className="flex items-center justify-start">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6"
                                  viewBox="0 0 24 24"
                              >
                                  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                      <g fillRule="nonzero" transform="translate(-528 -288)">
                                          <g transform="translate(528 288)">
                                              <path
                                                  fillRule="nonzero"
                                                  d="M24 0v24H0V0h24zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018zm.265-.113l-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022zm-.715.002a.023.023 0 00-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092z"
                                              ></path>
                                              <path
                                                  fill="#E6DED1"
                                                  d="M5.5 17a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-13-6.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM5.5 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM12 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
                                              ></path>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <div className="flex flex-col px-4 py-3 text-white">
                              <span className="text-base">{t.three_general_activity}</span>
                          </div>
                      </div>
                      <div className="flex flex-row w-full lg:w-10/12 rounded-r-2xl">
                          <div className="flex items-center justify-start">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6"
                                  viewBox="0 0 24 24"
                              >
                                  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                      <g fillRule="nonzero" transform="translate(-528 -288)">
                                          <g transform="translate(528 288)">
                                              <path
                                                  fillRule="nonzero"
                                                  d="M24 0v24H0V0h24zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018zm.265-.113l-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022zm-.715.002a.023.023 0 00-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092z"
                                              ></path>
                                              <path
                                                  fill="#E6DED1"
                                                  d="M5.5 17a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-13-6.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM5.5 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM12 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
                                              ></path>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <div className="flex flex-col px-4 py-3 text-white">
                              <span className="text-base">{t.four_general_activity}</span>
                          </div>
                      </div>
                      <div className="flex flex-row w-full lg:w-10/12 rounded-r-2xl">
                          <div className="flex items-center justify-start">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6"
                                  viewBox="0 0 24 24"
                              >
                                  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                      <g fillRule="nonzero" transform="translate(-528 -288)">
                                          <g transform="translate(528 288)">
                                              <path
                                                  fillRule="nonzero"
                                                  d="M24 0v24H0V0h24zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018zm.265-.113l-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022zm-.715.002a.023.023 0 00-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092z"
                                              ></path>
                                              <path
                                                  fill="#E6DED1"
                                                  d="M5.5 17a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-13-6.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM5.5 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM12 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.5 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
                                              ></path>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                          <div className="flex flex-col px-4 py-3 text-white">
                              <span className="text-base">{t.five_general_activity}</span>
                          </div>
                      </div>
                      <span className="lg:w-9/12 text-white text-sm mt-4">{t.p_two_general_activity}</span>
                  </div>
                  <div>
                      <img
                          className="w-9/12 sm:w-7/12 md:w-10/12 mx-auto max-h-[45rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"
                          src={law_activity}/>
                  </div>
              </div>
          </section>

          {/*  PROJECT */}
          <div className="relative bg-gray-100">
              <div>
                  <img className="h-10 md:h-12 absolute top-10 right-5" src={frame_line_blue}/>
              </div>

              <div className="lg:container flex flex-row w-11/12 mx-auto pt-24">
                  <div className="w-full md:w-7/12 flex flex-col">
                      <span className="font-bold text-2xl">{t.title_activity}</span>
                      <span className="w-11/12">{t.subtitle_activity}</span>
                  </div>
                  <div className="w-8/12 hidden md:flex justify-end items-center">
                      {/*<button*/}
                      {/*    className="justify-center bg-blue color-orange border-[#E6DED1] border py-2.5 px-3.5 rounded-xl">*/}
                      {/*    <span className="font-semibold">Consultanta</span>*/}
                      {/*</button>*/}
                  </div>
              </div>

              <div
                  className="lg:container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 w-11/12 mx-auto -mx-4 -my-8 py-10 mt-2">
                  <div className="w-11/12 mx-auto flex flex-col items-center justify-center">
                      <img className="w-28 mx-auto" src={icon_project_1}/>
                      <div className="flex flex-col items-center justify-center mt-6">
                          <span className="text-base text-center mt-4 font-bold">{t.protectBusiness}</span>
                      </div>
                  </div>
                  <div className="w-11/12 mx-auto flex flex-col items-center justify-center">
                      <img className="w-28 mx-auto" src={icon_project_2}/>
                      <div className="flex flex-col items-center justify-center mt-6">
                          <span className="font-bold text-base text-center">{t.analyzeRisks}</span>
                      </div>
                  </div>
                  <div className="w-11/12 mx-auto flex flex-col items-center justify-center">
                      <img className="w-28 mx-auto" src={icon_project_3}/>
                      <div className="flex flex-col items-center justify-center mt-6">
                          <span className="font-bold text-base text-center">{t.manageEmployeeDiscipline}</span>
                      </div>
                  </div>
                  <div className="w-11/12 mx-auto flex flex-col items-center justify-center">
                      <img className="w-28 mx-auto" src={icon_project_1}/>
                      <div className="flex flex-col items-center justify-center mt-6">
                          <span className="font-bold text-xl text-center">{t.permanentLegalConsulting}</span>
                      </div>
                  </div>
                  <div className="w-11/12 mx-auto flex flex-col items-center justify-center">
                      <img className="w-28 mx-auto" src={icon_project_2}/>
                      <div className="flex flex-col items-center justify-center mt-6">
                          <span className="font-bold text-base text-center">{t.securePartnerships}</span>
                      </div>
                  </div>
              </div>
      </div>


    <div className="relative w-full bg-gray-100 pt-16">
        <div className="flex flex-col justify-center">
            <span className="text-3xl text-center font-bold color-blue">{t.areas_of_activity}</span>
            <div className='w-24 mx-auto bg-blue h-1 mt-1 rounded-lg'/>

            <span className="w-11/12 mx-auto text-center text-base lg:text-lg text-[#373642] font-medium mt-4">
                      {t.p_areas_of_activity}
                  </span>
        </div>
        <Swiper
            style={{
                "--swiper-pagination-color": "#124E66",
                "--swiper-pagination-bullet-inactive-color": "#b8b8bb",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-size": "12px",
                "--swiper-pagination-bullet-horizontal-gap": "6px"
            }}
            slidesPerView="1"
            mousewheel={true}
            // direction="vertical"
            modules={[Pagination, Autoplay]}
            pagination={{clickable: true}}
            scrollbar={{draggable: true}}
            speed={550}
            autoplay={{
                delay: 4500,
                disableOnInteraction: false,
                  }}
                  className="mySwiper lg:container flex items-center mx-auto h-full max-h-[750px] md:max-h-[500px] mt-[20px]"
              >
                  {faqs.map((item, i) => {
                      return <SwiperSlide className="md:w-10/12 xl:w-11/12 mx-auto pb-12">
                          <div
                              className="w-full px-4 flex flex-col md:flex-row items-center justify-center my-auto pt-5 xl:pt-10">
                              {/*<div className="image-container flex md:hidden mx-auto w-8/12">*/}
                              {/*    <img*/}
                              {/*        className="lg:w-full h-full max-h-[15em] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                              {/*        src={blog1}/>*/}
                              {/*</div>*/}
                              <div className="w-11/12 lg:w-6/12 flex flex-col justify-start pt-7">
                                  <span className="md:w-8/12 text-start text-3xl lg:text-4xl font-bold">{item.title}</span>
                                  <span className="md:w-10/12 text-start text-base lg:text-lg font-medium mt-4">{item.description}</span>
                                  <button onClick={() => changeRoute(`/domenii/activitate?item=${i}`)}
                                          className="w-[9rem] font-semibold flex items-center justify-center bg-blue text-[16px] text-white rounded-xl py-2 px-2 mt-6">
                                      <span className="">{t.more}</span>
                                  </button>
                              </div>
                              <div className="hidden image-container lg:flex w-4/12">
                                  <img
                                      className="lg:w-full mx-auto max-h-[39rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"
                                      src={blog1}/>
                              </div>
                          </div>
                      </SwiperSlide>
                  })}
              </Swiper>
          </div>

          <div className="pt-8 bg-gray-100">
              <Footer/>
          </div>

      </div>
  );
}

export default HomePage;
