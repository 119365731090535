import {Disclosure} from "@headlessui/react";

const DisclosureItem = ({ isOpen, onClick, title, description, options }) => {

    console.log(title, isOpen)

    const opeen = false

    return (
        <div>
            {/*<Disclosure>*/}
            {/*    {({open}) => (*/}
            {/*        <div className='bg-white rounded-xl'>*/}
            {/*            <Disclosure.Button*/}
            {/*                onClick={onClick}*/}
            {/*                className="flex items-center justify-between focus:border-blue-500 w-full px-4 py-6 text-lg font-medium text-left text-gray-700">*/}
            {/*                <span className="ml-2.5 font-bold">{title}</span>*/}
            {/*                <div className="p-2 bg-blue rounded-full">*/}
            {/*                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
            {/*                         stroke-width="1.5" stroke="currentColor" className={`size-6 text-white ${*/}
            {/*                        isOpen ? 'transform rotate-[90deg]' : ''*/}
            {/*                    } w-5 h-5 transition-transform duration-300`}>*/}
            {/*                        <path stroke-linecap="round" stroke-linejoin="round"*/}
            {/*                              d="m8.25 4.5 7.5 7.5-7.5 7.5"/>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}

            {/*            </Disclosure.Button>*/}
            {/*            <Disclosure.Panel className={`ml-2.5 transition-max-height duration-500 ease-in-out ${*/}
            {/*                open ? 'max-h-screen' : 'max-h-0'*/}
            {/*            } overflow-hidden px-4 -pt-2 pb-2 text-md text-gray-500`}*/}
            {/*            >*/}
            {/*                {description}*/}
            {/*            </Disclosure.Panel>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</Disclosure>*/}
            <div className='bg-white rounded-xl'>
                <button
                    className="flex items-center justify-between focus:border-blue-500 w-full px-4 py-6 text-lg font-medium text-left text-gray-700"
                    onClick={onClick}
                >
                    <span className="ml-2.5 font-bold">{title}</span>
                    <div className="p-2 bg-blue rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke-width="1.5" stroke="currentColor" className={`size-6 text-white ${
                            isOpen ? 'transform rotate-[90deg]' : ''
                        } w-5 h-5 transition-transform duration-300`}>
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                        </svg>
                    </div>
                </button>
                <div
                    className={`ml-2.5 transition-max-height duration-500 ease-in-out ${
                        isOpen ? 'max-h-screen px-4 -pt-1 pb-2 text-md text-gray-500' : 'h-0 pt-1'
                    } overflow-hidden `}
                >
                    {description}

                    <ul className="ml-1 list-disc">
                        {options.map((item, i) => {
                            return <li>{item.name}</li>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DisclosureItem