import React from "react";
import { Link } from "react-router-dom";
// import logo from "../../img/logo.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Sidebar() {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1024);

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    }
    if (isDesktop || !sidebar) {
      document.body.style.overflow = "unset";
    }
  }, [sidebar, isDesktop]);

  function Logout() {
    localStorage.clear();
    navigate("/");
  }

  return (
    <div>
      <div
        className={`hidden md:block bg-blue min-h-screen h-full w-56 lg:w-64 xl:w-72 p-6 lg:p-8 `}
      >
        <Link to="/">
          {/*<img className={`mx-auto w-20 xl:w-24 `} src={logo} alt="" />*/}
        </Link>
        <ul
          className={`flex flex-col mt-8 lg:mt-10 space-y-2 lg:space-y-3 text-white text-lg  lg:text-xl xl:text-2xl`}
        >
          <Link to="/admin/dashboard">
            <li className={` hover:text-primary font-bold`}>Dashboard</li>
          </Link>
          <Link to="/admin/press">
            <li className={`hover:text-primary font-bold`}>Bloguri</li>
          </Link>
          <li
            onClick={Logout}
            className={`text-left hover:text-primary font-bold`}
          >
            Delogare
          </li>
        </ul>
      </div>

      <div
        className={`md:hidden bg-dark  relative w-full flex flex-row items-center justify-center lg:justify-start`}
      >
        <button
          onClick={showSidebar}
          className={`absolute left-4 xs:left-6 sm:left-8 block lg:hidden`}
        >
          <svg
            className={`w-6 s:w-8`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="24 / basic / text-align-left">
              <path
                id="icon"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 7V5H2V7H22ZM16 9V11H2V9H16ZM22 15V13H2V15H22ZM16 19V17H2V19H16Z"
                fill="white"
              />
            </g>
          </svg>
        </button>
        <Link to="/">
        {/*<img className={`w-20 2xl:ml-24`} src={logo} alt="" />*/}
        </Link>
      </div>
      {sidebar ? (
        <div
          className={` z-10 md:hidden fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-400 bg-opacity-70 transition-opacity`}
          onClick={showSidebar}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" lg:hidden absolute px-6 sm:px-8 py-4 top-0 right-0 h-screen w-full bg-[#1a1a1a]/70"
          >
            <div className="modal-header flex flex-row justify-end p-2">
              <button
                type="button"
                className="mt-1"
                data-dismiss="modal"
                aria-label="Close"
                onClick={showSidebar}
              >
                <svg
                  className={`w-4 s:w-5`}
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.49997 10.3575L2.60021 15.8456L0.0664062 13.4886L5.96617 8.00045L0.0664062 2.5123L2.60021 0.155273L8.49997 5.64343L14.3997 0.155273L16.9335 2.5123L11.0338 8.00045L16.9335 13.4886L14.3997 15.8456L8.49997 10.3575Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <nav className="flex flex-col mt-10 sm:mt-16 pl-2 ">
              <ul
                className={`flex flex-col text-white font-semibold text-xl s:text-2xl sm:text-3xl space-y-6 s:space-y-8`}
              >
                <Link to="/admin/dashboard">
                  {" "}
                  <li>Dashboard</li>
                </Link>
                <Link to="/admin/press">
                  <li>Press</li>
                </Link>
                <Link to="/admin/gallery">
                  <li>Gallery</li>
                </Link>
                <li onClick={Logout}>Log Out</li>
              </ul>
            </nav>
          </div>
        </div>
      ) : null}
    </div>
  );
}
